import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Axios from 'axios';
import config from 'Config';
import PasswordDialog from './PasswordDialog';
import moment from 'moment';


const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        margin: '5px',
        minWidth: '100%',
    },
    button: {
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
    },
});

class LeaguePage extends Component {
    constructor(props) {
        super(props);
        this.getCurrentDrinksChoice = this.getCurrentDrinksChoice.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.payButtonClicked = this.payButtonClicked.bind(this);
        this.getTodayAwayTeam = this.getTodayAwayTeam.bind(this);
        this.state = {
            qty: null,
            drinks: null,
            passwordDialogOpen: false,
            paid: null,
            awayTeam: null,
        };
    }

    getCurrentDrinksChoice() {
        return config.drinks;
    }

    getTodayAwayTeam() {
        const awayTeam = {
            '2019-07-20': 'TEST',
            '2019-08-07': 'DQER V',
            '2019-07-31': 'CAVE x DartsTwister',
            '2019-08-21': 'Darts Master - Just 4 fun',
            '2019-08-28': 'CAVE x DartsTwister',
            '2019-09-04': 'Rex Rabbit III',
            '2019-09-18': 'Rex Rabbit III',
            '2019-09-25': 'Hoffe X Night',
            '2019-10-09': 'Voldemort...s@DartsMarket',
            '2019-10-30': 'Hoffe X Night',
            '2019-11-06': 'DQER V',
            '2019-11-13': 'Voldemort...s@DartsMarket'
        };
        for (var key in awayTeam) {
            const date = moment(key);
            if (date.diff(moment(),'days') == 0) {
                this.setState({awayTeam: awayTeam[key]});
            }
        }
    }

    componentDidMount() {
        Axios.get(config.api + '/drinks/league-amount')
            .then((response) => {
                const drinksLeftAmount = response.data.reduce((sum, i) => {
                    return sum + i.qty;
                }, 0);
                this.setState({ qty: 12 - drinksLeftAmount });
            });
        Axios.get(config.api + '/drinks/league-drinks')
            .then((response) => {
                var finalResult = [];
                for (var i in response.data) {
                    const qty = response.data[i];
                    if (qty != 0) {
                        var name = this.getCurrentDrinksChoice()[parseInt(i) - 1];
                        finalResult.push({
                            name: name,
                            amount: qty
                        });
                    }
                }
                this.setState({ drinks: finalResult });
            });
        Axios.get(config.api + '/log/league-fee')
            .then((response) => {
                if (response.data.paid == 1) {
                    this.setState({ paid: true });
                }else {
                    this.setState({ paid: false });
                }
            });
        this.getTodayAwayTeam();
    }

    confirmPasswordDialoghandler(user) {
        Axios.post(config.api+'/log', {
            user,
            item: '客隊比賽(League)',
            itemId: 1,
            category: '其他',
            categoryId: 5,
            amount: 300,
            isDeleted: 0
        })
            .then(() => {
                this.setState({paid: true, passwordDialogOpen: false});
            });
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    payButtonClicked() {
        this.setState({
            passwordDialogOpen: true
        });
    }

    render() {
        const { classes } = this.props;
        var content;
        if (this.state.awayTeam == null) {
            content = (
                <Typography className={classes.content} variant="h5"> 今天 D8 DOJO & Workshop 沒有比賽 </Typography>
            );
        } else {
            content = (
                <div className={classes.root}>
                    <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                    <Card style={{ minWidth: '100%'}}>
                        <CardContent style={{paddingTop: '5px', paddingBottom: '5px'}}>
                            <Typography className={classes.content} variant="h5"> 作客隊伍： {this.state.awayTeam} </Typography>
                            <Typography style={{ marginLeft: '5px', marginTop: '15px', marginRight: '20px' }} variant="h6"> 作客最低消費： $300</Typography>
                            <CardActions>
                                {this.state.paid != false && this.state.paid != null ? 
                                    <Button style={{maxWidth: '100px'}} disabled className={classes.button} onClick={this.payButtonClicked}variant="contained">已支付</Button> :
                                    (this.state.paid == null ? null :
                                        <Button style={{maxWidth: '70px'}} className={classes.button} onClick={this.payButtonClicked}variant="contained">支付</Button>) 
                                }
                            </CardActions>
                        </CardContent>
                    </Card>
                    <Typography className={classes.content} style={{ backgroundColor: 'yellow', marginTop: '30px' }} variant="h5">剩餘飲品數量：{this.state.qty == null ? 'Loading...' : this.state.qty} </Typography>
                    <Typography className={classes.content} style={{marginTop: '20px'}} variant="h6">已拎取以下飲品：</Typography>
                    {this.state.drinks == null ?
                        <Typography variant="h6" style={{ marginLeft: '5px', marginTop: '30px' }}>Loading...</Typography> :
                        this.state.drinks.length == 0 ?
                            <Typography variant="h6" style={{ marginLeft: '5px', marginTop: '10px' }}>未領取任何飲品</Typography> :
                            this.state.drinks.map((object, index) => {
                                return (
                                    <Typography key={index} variant="h6" style={{ marginLeft: '5px', marginTop: '10px', minWidth: '100%' }}>{object.name + ' : ' + object.amount}</Typography>
                                );
                            })
                    }
                </div>
            );
        }
        return (
            <div>{content}</div>
        );
    }
}


LeaguePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeaguePage);