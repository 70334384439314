import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, InputLabel, Select, MenuItem, FormControl, Button, Snackbar } from '@material-ui/core';
import Axios from 'axios';
import config from 'Config';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: '100%'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    button: {
        backgroundColor: 'yellow',
        marginTop: '10px',
    }
});

class NewMemberPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            id: '',
            memberRank: '',
            snackBarOpen: false,
            message: '',
            email: '',
            telephone: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
        this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleConfirmClicked() {
        var message = '';
        if (this.state.id == '' || this.state.id.length != 11) {
            message += '請輸入11位會員號碼。';
        } 
        if (this.state.memberRank == '') {
            message += '請輸入會員等級。';
        }
        if (this.state.name == '') {
            message += '請輸入會員姓名。';
        }
        if (this.state.telephone == '') {
            message += '請輸入會員電話號碼。';
        }
        if (message != '') {
            this.setState({message, snackBarOpen: true}, () => {
                setTimeout(() => {this.setState({snackBarOpen: false});}, 2000);
            });
            return;
        }
        Axios.post(config.api + '/member', {
            id: this.state.id,
            name: this.state.name,
            role: this.state.memberRank,
            telephone: this.state.telephone,
            email: this.state.email
        })
            .then((response) => {
                if (response.status == 200) {
                    this.setState({snackBarOpen: true, message:'已新增會員。', id: '', name: '', telephone: '',memberRank: ''});
                } else {
                    this.setState({snackBarOpen: true, message:'新增會員時發生錯誤。'});
                }
            });
    }

    handleSnackBarClose() {
        this.setState({snackBarOpen: false});
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackBarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />
                <TextField
                    id="id"
                    label="會員編號"
                    autoFocus
                    className={classes.textField}
                    value={this.state.id}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'id'
                    }}
                    margin="normal"
                />
                <TextField
                    id="name"
                    label="姓名"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'name'
                    }}
                    margin="normal"
                />
                <TextField
                    id="telephone"
                    label="電話號碼"
                    className={classes.textField}
                    value={this.state.telephone}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'telephone'
                    }}
                    margin="normal"
                />
                <TextField
                    id="email"
                    label="Email"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'email'
                    }}
                    margin="normal"
                />
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.inputLabel}>會員等級</InputLabel>
                    <Select className={classes.select} value={this.state.memberRank} onChange={this.handleChange}
                        inputProps={{
                            name: 'memberRank',
                        }}>
                        {/* <MenuItem value="boss">SA</MenuItem>
                        <MenuItem value="staff">AA</MenuItem>
                        <MenuItem value="team">A</MenuItem>
                        <MenuItem value="normal">BB</MenuItem> */}
                        <MenuItem value="VIP">高級會員</MenuItem>
                        <MenuItem value="normal">普通會員</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>
                        確定
                </Button>
            </div>
        );
    }
}


NewMemberPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewMemberPage);