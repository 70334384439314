import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Snackbar } from '@material-ui/core';
import PasswordDialog from './PasswordDialog';
import axios from 'axios';
import config from 'Config';
import moment from 'moment';

const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    button: {
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '10px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
});

class CheckinoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.getCheckin = this.getCheckin.bind(this);
        this.doCheckin = this.doCheckin.bind(this);
        this.getCheckout = this.getCheckout.bind(this);
        this.doCheckout = this.doCheckout.bind(this);
        this.getData = this.getData.bind(this);
        this.updateIP = this.updateIP.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.state = {
            luCheckin: null,
            ninCheckin: null,
            luCheckout: null,
            ninCheckout:null,
            passwordDialogOpen: false,
            snackbarOpen: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.getCheckin('lu').then((result) => {
            if (result.data.length > 0) {
                this.setState({luCheckin: result.data[0].datetime});
            }
        });
        this.getCheckin('nin').then((result) => {
            if (result.data.length > 0) {
                this.setState({ninCheckin: result.data[0].datetime});
            }
        });
        this.getCheckout('lu').then((result) => {
            if (result.data.length > 0) {
                this.setState({luCheckout: result.data[0].datetime});
            }
        });
        this.getCheckout('nin').then((result) => {
            if (result.data.length > 0) {
                this.setState({ninCheckout: result.data[0].datetime});
            }
        });
    }

    getCheckin(name) {
        return new Promise((resolve) => {
            axios.get(config.api+'/checkinout/checkin?name=' + name)
                .then((response) => {
                    resolve(response);
                });
        });

    }

    doCheckin(name) {
        axios.get(config.api+'/password/pass')
            .then((response) => {
                if (response.data.pass == 1) {
                    axios.post(config.api+'/checkinout/checkin', {
                        name
                    })
                        .then((response) => {
                            this.getData();
                            console.log(response);
                        });
                }else {
                    this.setState({snackbarOpen: true});
                }
            });

    }

    getCheckout(name) {
        return new Promise((resolve) => {
            axios.get(config.api+'/checkinout/checkout?name=' + name)
                .then((response) => {
                    resolve(response);
                });
        });
    }

    doCheckout(name) {
        axios.get(config.api+'/password/pass') 
            .then((response) => {
                if (response.data.pass == 1) {
                    axios.post(config.api+'/checkinout/checkout', {
                        name
                    })
                        .then((response) => {
                            this.getData();
                            console.log(response);
                        });
                } else {
                    this.setState({snackbarOpen: true});
                }
            });
    }

    updateIP() {
        this.setState({passwordDialogOpen: true});
    }

    confirmPasswordDialoghandler(user) {
        if (user == 'Zachary') {
            axios.post(config.api+'/password/updateip')
                .then((response) => {
                    console.log(response);
                    this.setState({passwordDialogOpen: false});
                });
        } else {
            this.setState({snackbarOpen: true});
        }
    }

    closePasswordDialoghandler() {
        this.setState({passwordDialogOpen: false});
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={() => {this.setState({snackbarOpen: false});}}
                    message={<span>IP不正確</span>}
                />
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <Typography variant="h5" style={{marginTop: '10px', marginBottom: '20px', backgroundColor: 'yellow'}}>Check in</Typography>
                <Typography variant="h6">Lu 今天Check-in時間： {this.state.luCheckin != null ? moment(this.state.luCheckin).format('YYYY-MM-DD HH:mm') : '暫無'} </Typography>
                <Button variant="contained" className={classes.button} onClick={() => {this.doCheckin('lu');}}>
                    Check-in
                </Button>
                <Typography variant="h6">Nin 今天Check-in時間： {this.state.ninCheckin != null ? moment(this.state.ninCheckin).format('YYYY-MM-DD HH:mm') : '暫無'} </Typography>
                <Button variant="contained" className={classes.button} onClick={() => {this.doCheckin('nin');}}>
                    Check-in
                </Button>
                <hr />
                <Typography variant="h5" style={{marginTop: '10px', marginBottom: '20px', backgroundColor: 'yellow'}}>Check out</Typography>
                <Typography variant="h6">Lu 今天Check-out時間： {this.state.luCheckout != null ? moment(this.state.luCheckout).format('YYYY-MM-DD HH:mm') : '暫無'} </Typography>
                <Button variant="contained" className={classes.button} onClick={() => {this.doCheckout('lu');}}>
                    Check-out
                </Button>
                <Typography variant="h6">Nin 今天Check-out時間： {this.state.ninCheckout != null ? moment(this.state.ninCheckout).format('YYYY-MM-DD HH:mm') : '暫無'} </Typography>
                <Button variant="contained" className={classes.button} onClick={() => {this.doCheckout('nin');}}>
                    Check-out
                </Button>
                <hr />
                <Button variant="contained" className={classes.button} onClick={() => {this.updateIP();}}>
                    Update IP
                </Button>
            </div>
        );
    }
}

CheckinoutPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckinoutPage);