import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import config from 'Config';
import PasswordDialog from './PasswordDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px'
    },
    couponTitle: {
        fontWeight: '800',
        minWidth: '100%',
        marginTop: '4px'
    },
    coupon: {
        minWidth: '100%',
        margin: '0'
    }
});

// Role: team, sh, group
// Discount:   '0': '$50 唱 12 Credit',
//             '1': '$50 唱 15 Credit', 
//             '2': '$100 唱 26 Credit',
//             '3': '$100 唱 30 Credit',
//             '4': '$100 唱 40 Credit',
// const discountForGroup = {
//     'team': [1, 3],
//     'sh': [1, 3],
//     'group': [0, 2]
// };

// const peopleByGroup = {
//     'sh': ['Zachary', 'Kennis', 'Lloyd', 'Kit', 'B Jai', 'Wai', 'JC', 'Lewis', 'Pong', 'Nin'],
//     'team': ['Chiu', 'Ken', 'Alan', 'aR莉', '瑋', 'Waiting', 'Bird', 'Felix', 'Yin', 'Pan', 'Jacky', 'Bowin', 'Patrick'],
//     'group': ['Don', 'Gordon', 'Rachel']
// };

const discount = {
    '0': {
        'name': '$50 唱 12 Credit',
        'id': 0,
        'discount': -10
    },
    '1': {
        'name': '$50 唱 15 Credit',
        'id': 1,
        'discount': -25
    },
    '2': {
        'name': '$100 唱 26 Credit',
        'id': 2,
        'discount': -30
    },
    '3': {
        'name': '$100 唱 30 Credit',
        'id': 3,
        'discount': -50
    },
    '4': {
        'name': '$100 唱 40 Credit',
        'id': 4,
        'discount': -100
    },
    '5': {
        'name': '$100 唱 35 Credit',
        'id': 5,
        'discount': -75
    }
};

class ChangeMoneyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            bossNamesChoice: [],
            vipNamesChoice: [],
            memberNamesChoice: [],
            othersName: '',
            discount: '',
            message: '',
            discounts: [],
            open: false,
            passwordDialogOpen: false,
            coupons: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.confirmOnClick = this.confirmOnClick.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.popPeopleMenuItem = this.popPeopleMenuItem.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
    }

    componentDidMount() {
        this.popPeopleMenuItem();
        var allDiscounts = [];
        for (var k in discount) {
            allDiscounts.push(discount[k]);
        }
        this.setState({ discounts: allDiscounts });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        console.log(newProps.user);
        this.setState({user: newProps.user});
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            if (event.target.name == 'name') {
                let couponsTotal = event.target.value.coupons.reduce((pv,cv) => {
                    if (pv[String(cv.couponId)] == undefined) {
                        pv[String(cv.couponId)] = 1;
                    } else {
                        pv[String(cv.couponId)] += 1;
                    }
                    return pv;
                },{});

                this.setState({
                    coupons: couponsTotal
                });

                if (event.target.value.role == 'boss' || event.target.value.role == 'vip') {
                    var discounts = [discount['1'], discount['3']] ;
                    if (couponsTotal['1'] != undefined && couponsTotal['1'] > 0) {
                        discounts.push(discount['4']);
                    }
                    if (couponsTotal['2'] != undefined && couponsTotal['2'] > 0) {
                        discounts.push(discount['5']);
                    }
                    this.setState({
                        discounts
                    });
                } else if (event.target.value.role == 'member') {
                    var discountss = [discount['0'], discount['2']];
                    if (couponsTotal['1'] != undefined && couponsTotal['1'] > 0) {
                        discountss.push(discount['4']);
                    }
                    if (couponsTotal['2'] != undefined && couponsTotal['2'] > 0) {
                        discountss.push(discount['5']);
                    }
                    this.setState({
                        discounts: discountss
                    });
                }


                // if (event.target.value == 'Others') {
                //     var allDiscounts = [];
                //     for (var k in discount) {
                //         allDiscounts.push(discount[k]);
                //     }
                //     this.setState({ discounts: allDiscounts });
                //     return;
                // }
                // var group = '';
                // for (var key in peopleByGroup) {
                //     const peoples = peopleByGroup[key];
                //     if (peoples.includes(event.target.value)) {
                //         group = key;
                //         break;
                //     }
                // }
                // var selectedDiscounts = [];
                // const discounts = discountForGroup[group];
                // for (var i = 0; i < discounts.length; i++) {
                //     selectedDiscounts.push(discount[discounts[i]]);
                // }
                // this.setState({ discounts: selectedDiscounts });
            }
        });
    }

    handleTextChange(event) {
        this.setState({
            'othersName': event.target.value,
        });
    }

    popPeopleMenuItem() {
        // var names = [];
        // for (var gp in peopleByGroup) {
        //     names.push(peopleByGroup[gp]);
        // }
        // names.push('Others');
        // this.setState({ namesChoice: names.flat() });

        axios.get(config.api + '/member/getAllMembers').then((response) => {
            const boss = response.data.filter(e => e.role == 'boss').map((e) => {
                return {display: e.id + ' - ' + e.name, role: 'boss', coupons: e.coupons, id: e.id};
            });
            const vip = response.data.filter(e => e.role == 'VIP').map((e) => {
                return {display: e.id + ' - ' + e.name, role: 'vip', coupons: e.coupons, id: e.id};
            });
            const member = response.data.filter(e => e.role == 'normal').map((e) => {
                return {display: e.id + ' - ' + e.name, role: 'member', coupons: e.coupons, id: e.id};
            });
            this.setState({ bossNamesChoice: boss.flat(), vipNamesChoice: vip.flat(), memberNamesChoice: member.flat()});
        });

    }

    confirmOnClick() {
        if (this.state.discount == '') {
            this.setState({ 'open': true });
            return;
        }
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({passwordDialogOpen: true});
        }
    }

    handleDialogClose() {
        this.setState({ 'open': false });
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    confirmPasswordDialoghandler(user) {
        const currentTime = moment().format('DD/MM/YYYY h:mm a');
        const remark = this.state.othersName.length > 0 ? this.state.name.display + ' - ' + this.state.othersName : this.state.name.display;
        axios.post(config.api + '/log', {
            user: user,
            item: this.state.discount.name,
            itemId: this.state.discount.id,
            category: '唱錢',
            categoryId: 2,
            amount: this.state.discount.discount,
            remark,
            isDeleted: 0,
        })
            .then(() => {
                this.setState({ 'message': '[' + currentTime + '] ' + this.state.name.display + ' 使用 ' + this.state.discount.name });
                this.setState({ 'name': '', discount: ''});
                this.setState({passwordDialogOpen: false});
                setTimeout(() => {
                    this.setState({ 'message': '' });
                }, 2000);
            });
    }

    render() {
        const { classes } = this.props;
        let othersNameTextField;
        if (this.state.name == 'Others') {
            othersNameTextField = <TextField style={{ font: '18px' }} className={classes.formControl} value={this.state.othersName} label=''
                onChange={this.handleTextChange} />;
        }
        return (
            <div>
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title' className={classes.dialog}>{'請填寫所有資料'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialog} id='alert-dialog-description'>
                            請填寫唱錢者及唱錢優惠。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color='primary'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <form className={classes.root} autoComplete='off'>
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>唱錢者</InputLabel>
                        <Select className={classes.select} value={this.state.name} onChange={this.handleChange}
                            inputProps={{
                                name: 'name',
                            }}>
                            <ListSubheader style={{pointerEvents: 'none'}}>D8</ListSubheader>
                            {this.state.bossNamesChoice.map((e, i) => {
                                return (
                                    <MenuItem className={classes.menuItem} key={i} value={e}>{e.display}</MenuItem>
                                );
                            })}
                            <ListSubheader style={{pointerEvents: 'none'}}>VIP</ListSubheader>
                            {this.state.vipNamesChoice.map((e, i) => {
                                return (
                                    <MenuItem className={classes.menuItem} key={i} value={e}>{e.display}</MenuItem>
                                );
                            })}
                            <ListSubheader style={{pointerEvents: 'none'}}>Member</ListSubheader>
                            {this.state.memberNamesChoice.map((e, i) => {
                                return (
                                    <MenuItem className={classes.menuItem} key={i} value={e}>{e.display}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {othersNameTextField}
                    {Object.keys(this.state.coupons).length !== 0 &&
                        <div>
                            <Typography className={classes.couponTitle} variant="h6" style={{fontWeight: '800'}}>Coupons</Typography>
                        </div>
                    }
                    {Object.keys(this.state.coupons).map((e) => {
                        if (e == '1') {
                            return <Typography className={classes.coupon} variant="h6">$100 唱 40 Credits: {this.state.coupons[e]}</Typography>;
                        }
                        if (e == '2') {
                            return <Typography className={classes.coupon}  variant="h6">$100 唱 35 Credits: {this.state.coupons[e]}</Typography>;   
                        }
                    })}
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>唱錢優惠</InputLabel>
                        <Select className={classes.select} value={this.state.discount} onChange={this.handleChange}
                            inputProps={{
                                name: 'discount',
                            }}>
                            {this.state.discounts.map((e) => {
                                return (
                                    <MenuItem className={classes.menuItem} key={e.name} value={e}>{e.name}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Button variant='contained' className={classes.button} onClick={this.confirmOnClick}>
                        確定
                    </Button>
                </form>
                <Typography variant='h6' style={{ marginTop: '30px' }}> {this.state.message}</Typography>
            </div>
        );
    }
}

ChangeMoneyPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

Object.defineProperty(Array.prototype, 'flat', {
    value: function (depth = 1) {
        return this.reduce(function (flat, toFlatten) {
            return flat.concat((Array.isArray(toFlatten) && (depth - 1)) ? toFlatten.flat(depth - 1) : toFlatten);
        }, []);
    }
});

export default withStyles(styles)(ChangeMoneyPage);