import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import 'react-table/react-table.css';
import axios from 'axios';
import moment from 'moment';
import CurrentMoneyLogDialog from './CurrentMoneyLogDialog';
import { Grid } from '@material-ui/core';
import config from 'Config';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '90%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px'
    }
});

class CurrentMoneyLogPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            loading: true,
            rowData: {},
            from: [],
            selectedFrom: '',
            to: [],
            selectedTo: ''
        };
        this.handler = this.handler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.formatD8Time = this.formatD8Time.bind(this);
    }

    componentDidMount() {
        axios.get(config.api+'/shop/all-close')
            .then((res) => {
                console.log(res);
                this.setState({from: res.data});
                var toArray = res.data.slice(0, res.data.length-1);
                toArray.push('Now');
                this.setState({to: toArray});
            });
    }

    handler() {
        this.setState({
            open: false
        }, () => {
            this.setState({ loading: true });
            axios.get(config.api+'/log?range=today')
                .then((res) => {
                    this.setState({ data: res.data, loading: false });
                });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value },() => {
            const selectedFrom = this.state.selectedFrom != '' ? '?from=' + moment(this.state.selectedFrom, 'YYYY/MM/DD HH:mm').toISOString()  : '';
            const selectedTo = this.state.selectedTo != '' ? '&to=' + moment(this.state.selectedTo, 'YYYY/MM/DD HH:mm').toISOString()  : ''; 
            this.setState({ loading: true });
            axios.get(config.api+'/log' + selectedFrom + selectedTo)
                .then((res) => {
                    console.log(res.data);
                    this.setState({ data: res.data, loading:false});
                });
        });
    }

    formatD8Time(date) {
        var d = moment(date);
        if (d.hour >= 0 & d.hour <= 6) {
            d = d.subtract(1, 'days');
            return d.format('YYYY/MM/DD');
        }
        return d.format('YYYY/MM/DD');
    }

    render() {
        const columns = [{
            Header: 'Time',
            id: 'createdAt',
            accessor: d => moment(d.createdAt).format('DD/MM/YYYY HH:mm'),
            width: 130
        }, {
            Header: 'Item',
            accessor: 'item'
        },  {
            Header: 'Qty',
            accessor: 'qty',
            width: 40
        },{
            Header: 'Amount',
            id: 'amount',
            accessor: d => '$' + d.amount,
            width: 80,
        }, {
            Header: 'Remark',
            accessor: 'remark',
            width: 100
        }, {
            Header: 'By',
            accessor: 'createdBy',
            width: 80
        }];
        const { classes } = this.props;
        return (
            <div>
                <CurrentMoneyLogDialog closeHandler={this.handler} open={this.state.open} data={this.state.rowData} />
                <form autoComplete="off">
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.inputLabel}>由</InputLabel>
                                <Select className={classes.select} value={this.state.selectedFrom} onChange={this.handleChange}
                                    inputProps={{
                                        name: 'selectedFrom',
                                    }}>
                                    {this.state.from.map((e, i) => {
                                        return <MenuItem key={i} className={classes.menuItem} value={moment(e.close).format('YYYY/MM/DD HH:mm')}>{moment(e.close).format('YYYY/MM/DD HH:mm')}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.inputLabel}>至</InputLabel>
                                <Select className={classes.select} value={this.state.selectedTo} onChange={this.handleChange}
                                    inputProps={{
                                        name: 'selectedTo',
                                    }}>
                                    {this.state.to.map((e, i) => {
                                        if (e == 'Now') {
                                            return <MenuItem key={i} className={classes.menuItem} value={new Date()}>Now</MenuItem>;
                                        } else {
                                            return <MenuItem key={i} className={classes.menuItem} value={moment(e.close).format('YYYY/MM/DD HH:mm')}>{moment(e.close).format('YYYY/MM/DD HH:mm')}</MenuItem>;
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
                <ReactTable
                    data={this.state.data}
                    columns={columns}
                    loading={this.state.loading}
                    defaultPageSize={50}
                    onFetchData={() => {
                        this.setState({ loading: true });
                        axios.get(config.api+'/log?range=today')
                            .then((res) => {
                                this.setState({ data: res.data, loading: false });
                            });
                    }}
                    getTdProps={(state, rowInfo) => {
                        return {
                            onClick: () => {
                                this.setState({
                                    open: true,
                                    rowData: rowInfo.original
                                });
                            }
                        };
                    }}
                />
            </div>

        );
    }
}

CurrentMoneyLogPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrentMoneyLogPage);
