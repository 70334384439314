import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import config from 'Config';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bodyText: {
        fontSize: '28px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        margin: theme.spacing.unit,
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize: {
        fontSize: 50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
    message: {
        fontSize: '10px'
    }
});

class PasswordDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            password: '',
            data: {},
            loading: true,
            wrongPassword: false,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.confirmHandler = this.confirmHandler.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.open != this.state.open) {
            this.setState({
                open: props.open,
                password: '',
                wrongPassword: false
            });
        }
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.confirmHandler();
        }
    }

    confirmHandler() {
        axios.get(config.api + '/password?password=' + this.state.password).then((response) => {
            if (response.data !== undefined && response.data !== null) {
                this.props.confirmHandler(response.data.user, response.data.role);
            } else {
                this.setState({ wrongPassword: true });
            }
        });
    }

    render() {
        const { classes } = this.props;
        var message;
        var cancel;
        if (this.state.wrongPassword) {
            message = <Typography className={classes.message} variant="h6">密碼錯誤，請重新輸入。</Typography>;
        }
        if (this.props.closeHandler != undefined) {
            cancel = <Button onClick={this.props.closeHandler} color="primary">取消</Button>;
        }
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.state.open}
                    onClose={this.props.closeHandler}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ fontSize: '20px' }}>請輸入密碼</DialogTitle>
                    <DialogContent>
                        {message}
                        <TextField
                            style={{ fontSize: '30px' }}
                            margin="dense"
                            id="password"
                            label="密碼"
                            fullWidth
                            autoFocus
                            type="password"
                            onChange={this.handleChange}
                            value={this.state.password}
                            inputProps={{
                                name: 'password',
                                style: {
                                    fontSize: '16px'
                                }
                            }}
                            onKeyPress={this.handleKeyPress}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.confirmHandler} color="primary">
                            確定
                        </Button>
                        {cancel}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

PasswordDialog.propTypes = {
    open: PropTypes.bool,
    closeHandler: PropTypes.func,
    classes: PropTypes.object.isRequired,
    confirmHandler: PropTypes.func
};
PasswordDialog.defaultProps = { open: false };

export default withStyles(styles)(PasswordDialog);