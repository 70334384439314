import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import axios from 'axios';
import config from 'Config';
import PasswordDialog from './PasswordDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize:{
        fontSize:50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class FreePlayPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.handleInsertCoinClicked = this.handleInsertCoinClicked.bind(this);
        this.state = {
            selectedMachine: 1,
            hour: 1,
            people: 2,
            message: '',
            passwordDialogOpen: false,
            insertedCredits: 0,
        };
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleConfirmClicked() {
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({ passwordDialogOpen: true});
        }
    }

    handleInsertCoinClicked() {
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({ passwordDialogOpen: true});
        }
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    confirmPasswordDialoghandler(user) {
        if (this.state.insertedCredits == 0) {
            axios.post(config.api+'/log', {
                user: user,
                item: 'Machine ' + this.state.selectedMachine + ' ' + this.state.hour + 'hour(s)' + ' (' + this.state.people + ' people)',
                itemId: this.state.hour,
                category: '任掟',
                categoryId: 4,
                amount: 30 * this.state.hour * this.state.people,
                isDeleted: 0 
            })
                .then(() => {
                    const currentTime = moment().format('DD/MM/YYYY h:mm a');
                    this.setState({'message':  '[' + currentTime + '] ' + this.state.selectedMachine + '號機' + this.state.people + '人現正開始任掟' + this.state.hour + '小時'});
                    this.setState({'hour': 1, 'people': 2});
                    this.setState({ passwordDialogOpen: false});
                    setTimeout(() => {
                        this.setState({'message': ''});
                    }, 2000);
                });
        } else {
            axios.post(config.api+'/log', {
                user: user,
                item: '任掟入銀',
                itemId: 4,
                category: '支出',
                categoryId: 3,
                amount: -5 * this.state.insertedCredits,
                isDeleted: 0
            })
                .then(() => {
                    const currentTime = moment().format('DD/MM/YYYY h:mm a');
                    this.setState({'message':  '[' + currentTime + '] ' + '已入銀' + this.state.insertedCredits + 'Credits'});
                    this.setState({insertedCredits: 0});
                    this.setState({ passwordDialogOpen: false});
                    setTimeout(() => {
                        this.setState({'message': ''});
                    }, 2000);
                });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({user: newProps.user});
    }

    render() {
        const { classes } = this.props;
        return(
            <div>
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>機號</InputLabel>
                        <Select className={classes.select} value={this.state.selectedMachine} onChange={this.handleChange}
                            inputProps={{
                                name: 'selectedMachine',
                            }}>
                            <MenuItem className={classes.menuItem} key="1" value="1">1</MenuItem>
                            <MenuItem className={classes.menuItem} key="2" value="2">2</MenuItem>
                            <MenuItem className={classes.menuItem} key="3" value="3">3</MenuItem>
                            <MenuItem className={classes.menuItem} key="4" value="4">4</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField className={classes.formControl}
                        id="standard-number"
                        label="時數"
                        value={this.state.hour}
                        onChange={this.handleChange}
                        type="number"
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        InputProps={{
                            name: 'hour',
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        margin="normal"
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>人數</InputLabel>
                        <Select className={classes.select} value={this.state.people} onChange={this.handleChange}
                            inputProps={{
                                name: 'people',
                            }}>
                            <MenuItem className={classes.menuItem} key="2" value="2">2</MenuItem>
                            <MenuItem className={classes.menuItem} key="3" value="3">3</MenuItem>
                            <MenuItem className={classes.menuItem} key="4" value="4">4</MenuItem>
                            <MenuItem className={classes.menuItem} key="5" value="5">5</MenuItem>
                            <MenuItem className={classes.menuItem} key="6" value="6">6</MenuItem>
                            <MenuItem className={classes.menuItem} key="7" value="7">7</MenuItem>
                            <MenuItem className={classes.menuItem} key="8" value="8">8</MenuItem>
                            <MenuItem className={classes.menuItem} key="9" value="9">9</MenuItem>
                        </Select>
                    </FormControl>
                </form>
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>確定</Button>
                <hr />
                <TextField id="credit" label="Credit" type="number" value={this.state.insertedCredits} onChange={this.handleChange}
                    inputProps={{
                        name: 'insertedCredits'
                    }}
                />
                <Button variant="contained" style={{backgroundColor: 'yellow'}} onClick={this.handleInsertCoinClicked}>
                    任掟入銀
                </Button>
                
                <Typography variant="h6" style={{marginTop: '30px'}}> {this.state.message}</Typography>
                {/* <Typography variant="h6" style={{marginTop: '30px'}}> 現時任掟情況：</Typography> */}
            </div>

        );
    }
}

FreePlayPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FreePlayPage);