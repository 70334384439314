import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import config from 'Config';
import PasswordDialog from './PasswordDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize:{
        fontSize:50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class ExpensePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.state = {
            category: '',
            expense: 0,
            remark: '',
            message: '',
            open: false,
            passwordDialogOpen: false,
        };
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleConfirmClicked() {
        if (this.state.category == '') {
            this.setState({'open': true});
            return;
        }
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({
                passwordDialogOpen: true
            });
        }
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    confirmPasswordDialoghandler(user) {
        axios.post(config.api+'/log', {
            user,
            item: this.getCategory()[this.state.category],
            itemId: this.state.category,
            category: '支出',
            categoryId: 3,
            amount: -this.state.expense,
            remark: this.state.remark,
            isDeleted: 0
        })
            .then(() => {
                const currentTime = moment().format('DD/MM/YYYY h:mm a');
                this.setState({'message':  '[' + currentTime + '] ' + ' 支出 ' + this.getCategory()[this.state.category] + ' $' + this.state.expense});
                this.setState({'expense': 0, 'category': ''});
                this.setState({passwordDialogOpen: false});
                setTimeout(() => {
                    this.setState({'message': ''});
                }, 2000);
            });
    }

    getCategory() {
        return (['雜項', '鏢銀找數', '零食', '飲品入貨', '食銀', '退貨', '福利部', '比賽入銀', '其他', '少錢']);
    }

    handleDialogClose() {
        this.setState({'open': false});
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({user: newProps.user});
    }

    render() {
        const { classes } = this.props;
        return(
            <div>
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id="alert-dialog-title" className={classes.dialog}>{'請填寫所有資料'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialog} id="alert-dialog-description">
                         請填寫類別。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>類別</InputLabel>
                        <Select className={classes.select} value={this.state.category} onChange={this.handleChange}
                            inputProps={{
                                name: 'category',
                            }}>
                            <MenuItem className={classes.menuItem} key="雜項" value="0">雜項</MenuItem>
                            <MenuItem className={classes.menuItem} key="鏢銀找數" value="1">鏢銀找數</MenuItem>
                            <MenuItem className={classes.menuItem} key="零食" value="2">零食</MenuItem>
                            <MenuItem className={classes.menuItem} key="飲品入貨" value="3">飲品入貨</MenuItem>
                            <MenuItem className={classes.menuItem} key="食銀" value="4">食銀</MenuItem>
                            <MenuItem className={classes.menuItem} key="退貨" value="5">退貨</MenuItem>
                            <MenuItem className={classes.menuItem} key="福利部" value="6">福利部</MenuItem>
                            <MenuItem className={classes.menuItem} key="比賽入銀" value="7">比賽入銀</MenuItem>
                            <MenuItem className={classes.menuItem} key="其他" value="8">其他</MenuItem>
                            <MenuItem className={classes.menuItem} key="少錢" value="9">少錢</MenuItem>
                        </Select>
                    </FormControl>        
                    <TextField className={classes.formControl}
                        id="standard-number"
                        label="金額"
                        value={this.state.expense}
                        onChange={this.handleChange}
                        type="number"
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        InputProps={{
                            name: 'expense',
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        margin="normal"
                    />
                    <TextField className={classes.formControl}
                        id="remark"
                        label="備註"
                        value={this.state.remark}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        InputProps={{
                            name: 'remark',
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        margin="normal"
                    />
                </form>
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>
                        確定
                </Button>
                <Typography variant="h6" style={{marginTop: '30px'}}> {this.state.message}</Typography>
            </div>

        );
    }
}

ExpensePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExpensePage);