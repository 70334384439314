import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import axios from 'axios';
import config from 'Config';
import PasswordDialog from './PasswordDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        margin: theme.spacing.unit,
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize: {
        fontSize: 50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class DrinkPage extends React.Component {
    constructor(props) {
        super(props);
        this.popDrinksMenuItem = this.popDrinksMenuItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.popPeopleMenuItem = this.popPeopleMenuItem.bind(this);
        this.state = {
            selectedDrink: 0,
            qty: 1,
            amount: 15,
            message: '',
            passwordDialogOpen: false,
            buyer: '',
            buyerId: 0
        };
    }

    getCurrentDrinksChoice() {
        return config.drinks;
    }

    popDrinksMenuItem() {
        const { classes } = this.props;
        const drinks = this.getCurrentDrinksChoice();
        var drinksMenuItem = [];
        drinks.forEach((drink, index) => {
            drinksMenuItem.push(<MenuItem className={classes.menuItem} key={drink} value={index}>{drink}</MenuItem>);
        });
        return (drinksMenuItem);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleConfirmClicked() {
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({
                passwordDialogOpen: true
            });
        }
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    confirmPasswordDialoghandler(user) {
        axios.post(config.api + '/log', {
            user,
            item: this.getCurrentDrinksChoice()[this.state.selectedDrink],
            itemId: this.state.selectedDrink + 1,
            category: '飲品',
            categoryId: 1,
            amount: parseInt(this.state.amount) < 0 ? 0 : parseInt(this.state.amount) * this.state.qty,
            isDeleted: 0,
            qty: this.state.qty,
            remark: parseInt(this.state.amount) < 0 ? 'Coupon: ' + this.state.buyer : this.state.buyer
        })
            .then(() => {
                const currentTime = moment().format('DD/MM/YYYY h:mm a');
                this.setState({ 'message': '[' + currentTime + '] ' + this.getCurrentDrinksChoice()[this.state.selectedDrink] + ' 售出 ' + this.state.qty + '枝' });
                this.setState({ 'selectedDrink': 0, 'qty': 1, 'amount': 15, 'adjustAmount': 0, 'buyer': '' });
                this.setState({ passwordDialogOpen: false });
                setTimeout(() => {
                    this.setState({ 'message': '' });
                }, 2000);
            })
            .catch(error => console.log(error));

        if (parseInt(this.state.amount) < 0) {
            axios.post(config.api + '/member/use-coupon', {
                id: this.state.buyer,
                couponId: 3
            }).then(() => {
                this.popPeopleMenuItem();
            });
        }
    }

    componentDidMount() {
        this.popPeopleMenuItem();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ user: newProps.user });
    }

    popPeopleMenuItem() {
        axios.get(config.api + '/member/getAllMembers').then((response) => {
            const boss = response.data.filter(e => e.role == 'boss').map((e) => {
                return { display: e.id + ' - ' + e.name, role: 'boss', coupons: e.coupons, id: e.id };
            });
            const vip = response.data.filter(e => e.role == 'VIP').map((e) => {
                return { display: e.id + ' - ' + e.name, role: 'vip', coupons: e.coupons, id: e.id };
            });
            const member = response.data.filter(e => e.role == 'normal').map((e) => {
                return { display: e.id + ' - ' + e.name, role: 'member', coupons: e.coupons, id: e.id };
            });
            const couponsPeople = response.data.filter(e => {
                if (e.coupons.filter(f => f.couponId == 3).length > 0) {
                    return e;
                }
            });
            console.log(couponsPeople);
            this.setState({ bossNamesChoice: boss.flat(), vipNamesChoice: vip.flat(), memberNamesChoice: member.flat(), couponsPeople: couponsPeople.flat() });
        });
    }

    render() {
        const { classes } = this.props;
        var staffPriceMenuItem;
        if (this.state.selectedDrink == 18) {
            staffPriceMenuItem = <MenuItem className={classes.menuItem} key="D8" value="7">D8 ($7)</MenuItem>;
        } else if (this.state.selectedDrink == 8) {
            staffPriceMenuItem = <MenuItem className={classes.menuItem} key="D8" value="5">D8 ($5)</MenuItem>;
        } else if (this.state.selectedDrink == 24) {
            staffPriceMenuItem = <MenuItem className={classes.menuItem} key="D8" value="8">D8 ($8)</MenuItem>;
        }
        else {
            staffPriceMenuItem = <MenuItem className={classes.menuItem} key="D8" value="6">D8</MenuItem>;
        }
        // const st = ['Zachary', 'Lloyd', 'Kennis', 'Kit', 'B Jai', 'Wai', 'JC', 'Lewis'];
        // const vip = ['Chiu', 'Ken', 'Alan', 'Nin', 'Pong', 'aR莉', '瑋', 'Waiting', 'Bird', 'Felix', 'Yin', 'Pan', 'Jacky', 'Bowin', 'Patrick'];
        return (
            <div>
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>飲品</InputLabel>
                        <Select className={classes.select} value={this.state.selectedDrink} onChange={this.handleChange}
                            inputProps={{
                                name: 'selectedDrink',
                            }}>
                            {this.popDrinksMenuItem()}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>價錢</InputLabel>
                        <Select className={classes.select} value={this.state.amount} onChange={this.handleChange}
                            inputProps={{
                                name: 'amount'
                            }}>
                            <MenuItem className={classes.menuItem} key="客隊飲品" value="0">客隊飲品</MenuItem>
                            <MenuItem className={classes.menuItem} key="coupon" value="-1">飲品券</MenuItem>
                            {staffPriceMenuItem}
                            <MenuItem className={classes.menuItem} key="優惠價" value="10">優惠價/學生價</MenuItem>
                            <MenuItem className={classes.menuItem} key="售價" value="15">原價 ($15)</MenuItem>
                        </Select>
                    </FormControl>
                    {this.state.amount == 6 || this.state.amount == 7 || this.state.amount == 8 || this.state.amount == 5 ? (
                        <FormControl className={classes.formControl}>
                            <InputLabel className={classes.inputLabel}>購買者</InputLabel>
                            <Select className={classes.select} value={this.state.buyer} onChange={this.handleChange}
                                inputProps={{
                                    name: 'buyer',
                                }}>
                                {this.state.bossNamesChoice.map((e, i) => {
                                    return <MenuItem className={classes.menuItem} key={i} value={e.display}>{e.display}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>) : null
                    }
                    {this.state.amount == 10 ? (
                        <FormControl className={classes.formControl}>
                            <InputLabel className={classes.inputLabel}>購買者</InputLabel>
                            <Select className={classes.select} value={this.state.buyer} onChange={this.handleChange}
                                inputProps={{
                                    name: 'buyer',
                                }}>
                                {this.state.vipNamesChoice.map((e, i) => {
                                    return <MenuItem className={classes.menuItem} key={i} value={e.display}>{e.display}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>) : null
                    }
                    {this.state.amount == -1 ? (
                        <FormControl className={classes.formControl}>
                            <InputLabel className={classes.inputLabel}>購買者 (Coupon)</InputLabel>
                            <Select className={classes.select} value={this.state.buyer} onChange={this.handleChange}
                                inputProps={{
                                    name: 'buyer',
                                }}>
                                {this.state.couponsPeople.map((e, i) => {
                                    return <MenuItem className={classes.menuItem} key={i} value={e.id}>{e.id} - {e.name} x{e.coupons.filter(e => e.couponId == 3).length}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>) : null
                    }
                </form>
                <TextField
                    id="standard-number"
                    label="數量"
                    value={this.state.qty}
                    onChange={this.handleChange}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    InputProps={{
                        name: 'qty',
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    margin="normal"
                />
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>
                    確定
                </Button>
                <Typography variant="h6" style={{ marginTop: '30px' }}> {this.state.message}</Typography>
            </div>

        );
    }
}

DrinkPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrinkPage);