import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PasswordDialog from './PasswordDialog';
import { Typography, TextField, Button} from '@material-ui/core';
import { DialogContent, Dialog, DialogTitle, Link, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import axios from 'axios';
// import moment from 'moment';
import config from 'Config';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    progress: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        flex: '1',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    confirmButton: {
        marginTop: '10px',
        marginRight: '10px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px',
        color: 'black'
    },
    textField: {
        marginRight: '20px'
    }
});

class OrderItemPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sdartsURL: '',
            searchResult: null,
            queryResultByKeywords: null,
            isLoading: false,
            itemName: '',
            contactPerson: '',
            contact: '',
            total: 0,
            received: 0,
            passwordDialogOpen: false,
            outstandingOpen: false,
            outstanding: [],
            memberList: null,
            selectedMember: '',
            email: ''
        };
        this.searchOnClick = this.searchOnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.confirm = this.confirm.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.outstandingCloseHandler = this.outstandingCloseHandler.bind(this);
        this.loadOutstanding = this.loadOutstanding.bind(this);
        this.popPeopleMenuItem = this.popPeopleMenuItem.bind(this);
    }

    handleChange(event) {
        if (event.target.name == 'selectedMember') {
            this.setState({contact: event.target.value.telephone, contactPerson: event.target.value.name + ' (' + event.target.value.id + ')', email: event.target.value.email});
            if (event.target.value.email == undefined) {
                this.setState({email: ''});
            }
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    searchOnClick() {
        this.setState({isLoading: true});
        if (this.state.sdartsURL.startsWith('https://')) {
            axios.get(config.api + '/order/search?sdartsURL=' + this.state.sdartsURL).then((response) => {
                this.setState({isLoading:false, searchResult: response.data, itemName: response.data.name, queryResultByKeywords: null});
            });
        } else {
            axios.get(config.api + '/order/search?query=' + encodeURIComponent(this.state.sdartsURL.replace(' ', '+'))).then((response) => {
                console.log(response.data);
                this.setState({isLoading: false, queryResultByKeywords: response.data, searchResult: null});
            });
        }

    }

    confirm(user) {
        axios.post(config.api + '/order/add', {
            itemName: this.state.itemName,
            url: this.state.sdartsURL,
            name: this.state.contactPerson + ' (' + this.state.contact + ')',
            sdartsPrice: Math.floor(this.state.searchResult.price/3.75 * 100)/100,
            d8Price: this.state.total,
            received: this.state.received
        }).then(() => {
            axios.post(config.api + '/log', {
                user,
                item: '訂金 - ' + this.state.itemName,
                category: '貨品',
                categoryId: 6,
                amount: this.state.received,
                remark: this.state.contactPerson + ' (' + this.state.contact + ')',
                isDeleted: 0,
                qty: 1
            }).then(() => {
                this.setState({message: '已收訂金: ' + this.state.received, passwordDialogOpen: false, itemName: '', sdartsURL: '', searchResult: null, contactPerson: '' , contact: '', total: 0, received: 0});
            });
            setTimeout(() => {
                this.setState({message: ''});
            },2000);
        });


    }

    closePasswordDialoghandler() {
        this.setState({passwordDialogOpen: false});
    }

    outstandingCloseHandler() {
        this.setState({outstandingOpen: false});
    }

    componentDidMount() {
        this.loadOutstanding();
        this.popPeopleMenuItem();
    }

    loadOutstanding() {
        axios.get(config.api + '/order/outstanding').then((response) => {
            this.setState({outstanding: response.data});
        });
    }

    popPeopleMenuItem() {
        axios.get(config.api + '/member/getAllMembers').then((response) => {
            this.setState({ memberList: response.data});
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <PasswordDialog confirmHandler={this.confirm} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <Dialog open={this.state.outstandingOpen} onClose={this.outstandingCloseHandler} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">現時訂單</DialogTitle>
                    <DialogContent>
                        {this.state.outstanding.map((e,i) => {
                            return(
                                <div key={i}>
                                    <Typography>Item: {e[0]}</Typography>
                                    <Link href={e[1]} target="_blank">Link: {e[1]}</Link>
                                    <Typography>Contact: {e[2]}</Typography>
                                    <Typography>Total: {e[4]}</Typography>
                                    <Typography>Received: {e[5]}</Typography>
                                    <hr/>
                                </div>
                            );
                        })}
                    </DialogContent>
                </Dialog>
                <div style={{ margin: '5px' }}>
                    <Typography className={classes.inputLabel} style={{ fontWeight: '800'}}>訂貨</Typography>
                    <Button variant="flat" color="primary" onClick={() => this.setState({outstandingOpen: true})}>現時訂單</Button>
                    <TextField label="S-Darts URL / Keywords" value={this.state.sdartsURL} onChange={this.handleChange}
                        InputProps={{
                            name: 'sdartsURL'
                        }}
                        autoFocus
                        margin="dense"
                        fullWidth
                        placeholder="https://www.s-darts.tw/c/product/xxxxxxxxxxxxxx"
                    />
                    <Button variant="contained" color="primary" onClick={this.searchOnClick}>搜尋</Button>
                    {this.state.isLoading == true &&
                    <Typography className={classes.inputLabel}>Loading...</Typography>
                    }
                    {this.state.isLoading == false && this.state.searchResult != null &&
                    <div style={{marginTop: '10px'}}>
                        <Typography className={classes.inputLabel}>{this.state.searchResult.name}</Typography>
                        <img src={this.state.searchResult.imageSrc} style={{maxWidth: '100%', maxHeight: '200px'}}></img>
                        <Typography className={classes.inputLabel}>S-Darts 價錢: NT${this.state.searchResult.price} HKD${Math.floor(this.state.searchResult.price/3.75 * 100)/100}</Typography>
                        <Typography className={classes.inputLabel}>成本價: NT${this.state.searchResult.wholesalePrice} HKD${Math.floor(this.state.searchResult.wholesalePrice/3.75*100)/100}</Typography>
                        <Typography className={classes.inputLabel}>庫存: {this.state.searchResult.stock}</Typography>
                    </div>
                    }
                    {this.state.isLoading == false && this.state.queryResultByKeywords != null &&
                    <div style={{marginTop: '10px'}}>
                        {this.state.queryResultByKeywords.map((e,i) => {
                            return(<div key={i}>
                                <Typography key={i+999} className={classes.inputLabel}>{e.maker} - {e.name} {e.isAvailable != '' ? ' (' + e.isAvailable + ')' : ''}</Typography>
                                <Button key={i+9999} variant="contained" color="primary" onClick={() => {
                                    this.setState({isLoading: true, queryResultByKeywords: null, sdartsURL: e.link}, () => {
                                        this.searchOnClick();
                                    });
                                }}>Search</Button>
                            </div>);
                        })}
                    </div>
                    }
                    <div style={{marginTop: '10px'}}>
                        <TextField label="訂購貨品" value={this.state.itemName} onChange={this.handleChange}
                            InputProps={{
                                name: 'itemName'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        {this.state.memberList != null &&
                        <FormControl style={{minWidth: '100%'}}>
                            <InputLabel className={classes.inputLabel}>會員</InputLabel>
                            <Select value={this.state.selectedMember} onChange={this.handleChange}
                                inputProps={{
                                    name: 'selectedMember',
                                }}>
                                {this.state.memberList.map((e, i) => {
                                    return <MenuItem key={i} value={e}>{e.id} - {e.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                        }
                        <TextField label="聯絡人" value={this.state.contactPerson} onChange={this.handleChange}
                            InputProps={{
                                name: 'contactPerson'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        <TextField label="聯絡電話" value={this.state.contact} onChange={this.handleChange}
                            InputProps={{
                                name: 'contact'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        <TextField label="Email" value={this.state.email} onChange={this.handleChange}
                            InputProps={{
                                name: 'email'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        <TextField label="全數" value={this.state.total} onChange={this.handleChange}
                            InputProps={{
                                name: 'total'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        <TextField label="已付" value={this.state.received} onChange={this.handleChange}
                            InputProps={{
                                name: 'received'
                            }}
                            margin="dense"
                            fullWidth
                        />
                        <Button variant="contained" color="primary" onClick={() => this.setState({passwordDialogOpen: true})}>確定</Button>
                        <Typography className={classes.inputLabel} style={{marginTop:'10px'}}>{this.state.message}</Typography>
                    </div>
                </div>
            </div>

        );
    }
}

OrderItemPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderItemPage);