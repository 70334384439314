import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Link, TextField } from '@material-ui/core';
import { DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle, Checkbox, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import moment from 'moment';
import config from 'Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    progress: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        flex: '1',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    confirmButton: {
        marginTop: '10px',
        marginRight: '10px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px',
        color: 'black'
    },
    textField: {
        marginRight: '20px'
    }
});



class TodayClosePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amountTotal: null,
            drinks: null,
            goods: null,
            open: false,
            openConfirm: false,
            close: null,
            fivedollar: 0,
            otherfivedollar: 0,
            latestIncome: {},
            loading: false,
            loadingDrink: false,
            loadingGoods: false,
            showIncomeDialogHeading: false,
            drinkMessage: '',
            disableDrinkButton: false,
            disableIncomeButton: false,
            disableGoodsButton: false,
            checkboxOne: false,
            checkboxTwo: false,
            checkboxThree: false,
            checkboxFour: false,
            checkboxFive: false,
            checkboxSix: false,
            checkboxSeven: false
        };
        this.getCurrentDrinksChoice = this.getCurrentDrinksChoice.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
        this.confirmOnClick = this.confirmOnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.incomeToExcel = this.incomeToExcel.bind(this);
        this.drinkToExcel = this.drinkToExcel.bind(this);
        this.goodsToExcel = this.goodsToExcel.bind(this);
        this.buildIncomeText = this.buildIncomeText.bind(this);
        this.showIncome = this.showIncome.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    getCurrentDrinksChoice() {
        return config.drinks;
    }

    componentDidMount() {
        this.getData();
    }

    handleCheckboxChange(event) {
        this.setState({ [event.target.value]: event.target.checked });
    }

    getData() {
        axios.get(config.api + '/log/today/income')
            .then((response) => {
                this.setState({ amountTotal: response.data.result });
            });
        axios.get(config.api + '/log/today/drink')
            .then((response) => {
                var finalResult = [];
                for (var i in response.data) {
                    const qty = response.data[i];
                    if (qty != 0) {
                        var name = this.getCurrentDrinksChoice()[parseInt(i) - 1];
                        finalResult.push({
                            name: name,
                            amount: qty
                        });
                    }
                }
                this.setState({ drinks: finalResult });
            });
        axios.get(config.api + '/log/today/goods')
            .then((response) => {
                this.setState({ goods: response.data });
            });
        axios.get(config.api + '/shop/close')
            .then((response) => {
                this.setState({ close: response.data.close });
            });

        axios.get(config.api + '/income/isTodayAddedToGoogle')
            .then((response) => {
                this.setState({ disableIncomeButton: response.data });
            });
    }

    handleDialogClose() {
        this.setState({ 'open': false });
    }

    handleConfirmDialogClose() {
        this.setState({ openConfirm: false });
    }

    handleConfirm() {
        axios.post(config.api + '/shop/close', {
            user: 'Zachary'
        })
            .then(() => {
                this.setState({ 'open': false }, () => {
                    this.getData();
                });
            });
    }

    confirmOnClick() {
        this.setState({ 'open': true });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    incomeToExcel() {
        this.setState({ loading: true });
        axios.post(config.api + '/income', {
            income: this.state.amountTotal,
            fivedollar: this.state.fivedollar,
            otherfivedollar: this.state.otherfivedollar
        }).then(() => {
            axios.get(config.api + '/income/latestIncome').then((income) => {
                this.setState({ latestIncome: income.data, openConfirm: true, loading: false, showIncomeDialogHeading: true }, () => {
                    this.setState({ incomeMessage: this.buildIncomeText(), fivedollar: 0, disableIncomeButton: true });
                });
            });
        });
    }

    showIncome() {
        axios.get(config.api + '/roster').then((response) => {
            axios.get(config.api + '/income/latestIncome').then((income) => {
                this.setState({ latestIncome: income.data, openConfirm: true, loading: false, showIncomeDialogHeading: false }, () => {
                    this.setState({ incomeMessage: this.buildIncomeText() + '--------------\n明天更表\n' + response.data.result });
                });
            });
        });
    }

    drinkToExcel() {
        this.setState({ loadingDrink: true });
        var drinkData = {};
        var soldDrink = this.state.drinks;
        for (var j = 0; j < soldDrink.length; j++) {
            drinkData[parseInt(soldDrink[j].name.match(/\b(\d|\d\d)\b/g)[0])] = -soldDrink[j].amount;
        }
        for (var n = 1; n < config.drinks.length + 1; n++) {
            if (drinkData[n] == undefined) {
                drinkData[n] = 0;
            }
        }
        console.log(drinkData);
        axios.post(config.api + '/drinks', {
            data: drinkData
        }).then((response) => {
            if (response.status == 200) {
                this.setState({ loadingDrink: false, drinkMessage: '飲品已輸入至Excel', disableDrinkButton: true });
            } else {
                this.setState({ loadingDrink: false, drinkMessage: 'Error: ' + response.statusText });
            }
        });
    }

    goodsToExcel() {
        this.setState({ loadingGoods: true });
        const goods = this.state.goods;
        var items = '';
        var cats = '';
        var qtys = '';
        for (var i = 0; i < goods.length; i++) {
            items += goods[i].item;
            cats += goods[i].goods_cat;
            qtys += -parseInt(goods[i].qty);
            if (i != goods.length - 1) {
                items += ',';
                cats += ',';
                qtys += ',';
            }
        }
        axios.post(config.api + '/goods/today_sold', {
            items,
            cats,
            qtys
        }).then((response) => {
            console.log(response);
            this.setState({ loadingGoods: false, disableGoodsButton: true });
        }).catch(err => {
            console.log(err);
            this.setState({ loadingGoods: false, disableGoodsButton: true });
        });

        for (var j = 0; j < goods.length; j++) {
            if (goods[j].goods_itemId !== undefined) {
                axios.post(config.inventoryApi + '/sales', {
                    itemId: goods[j].goods_itemId,
                    qty: goods[j].qty,
                    memberId: goods[j].remark !== undefined ? (goods[j].remark.startsWith('2018416') ? parseInt(goods[j].remark.match(/\d+/g)[0]) : 0) : 0,
                    price: goods[j].amount
                });
            }
        }
    }

    getRoster() {
        return new Promise((resolve, reject) => {
            axios.get(config.api + '/roster')
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    buildIncomeText() {
        return (
            '日期:' + this.state.latestIncome['date'] + '\n' +
            '櫃桶:' + this.state.latestIncome['drawer'] + '\n' +
            'Cashbox:' + this.state.latestIncome['lastCashbox'] + '\n' +
            '一盒盒5蚊: ' + this.state.latestIncome['fivedollar'] + '\n' +
            '散5蚊:' + (this.state.latestIncome['otherfivedollar'] == null ? '0' : this.state.latestIncome['otherfivedollar']) + '\n'
            // '需加銀' + this.state.latestIncome['fivedollarLeft'] + '盒'
        );
    }

    render() {
        const { classes } = this.props;
        var incomeButtonSet;
        if (this.state.loading == true) {
            incomeButtonSet = <CircularProgress className={classes.progress} />;
        } else {
            if (this.state.disableIncomeButton == true) {
                incomeButtonSet = (
                    <Button variant="contained" disabled className={classes.confirmButton} onClick={this.incomeToExcel}>
                        已輸入
                    </Button>
                );
            } else {
                incomeButtonSet = (
                    <Button variant="contained" className={classes.confirmButton} onClick={this.incomeToExcel}>
                        輸入至 Excel
                    </Button>
                );
            }
        }
        var drinkButtonSet;
        if (this.state.loadingDrink == true) {
            drinkButtonSet = <CircularProgress className={classes.progress} />;
        } else {
            if (this.state.disableDrinkButton == true) {
                drinkButtonSet = (
                    <Button variant="contained" disabled style={{ marginTop: '10px' }} className={classes.button} onClick={this.drinkToExcel}>
                        已輸入
                    </Button>
                );
            } else {
                drinkButtonSet = (
                    <Button variant="contained" style={{ marginTop: '10px' }} className={classes.button} onClick={this.drinkToExcel}>
                        輸入至 Excel
                    </Button>
                );
            }
        }
        var goodsButtonSet;
        if (this.state.loadingGoods == true) {
            goodsButtonSet = <CircularProgress className={classes.progress} />;
        } else {
            if (this.state.disableGoodsButton == true) {
                goodsButtonSet = <Button disabled variant="contained" style={{ marginTop: '10px' }} className={classes.button} onClick={this.goodsToExcel}>輸入至 Excel</Button>;
            } else {
                goodsButtonSet = <Button variant="contained" style={{ marginTop: '10px' }} className={classes.button} onClick={this.goodsToExcel}>輸入至 Excel</Button>;
            }
        }
        var incomeDialogHeading;
        if (this.state.showIncomeDialogHeading == true) {
            incomeDialogHeading = <DialogTitle id="alert-dialog-title" className={classes.dialog}>{'收入資料已輸入至Excel'}</DialogTitle>;
        } else {
            incomeDialogHeading = <DialogTitle id="alert-dialog-title" className={classes.dialog}>收入資料</DialogTitle>;
        }
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id="alert-dialog-title" className={classes.dialog}>{'確定'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialog} id="alert-dialog-description">
                            確定已完成收鋪程序？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm} color="primary">
                            確定
                        </Button>
                        <Button onClick={this.handleDialogClose} color="primary">
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openConfirm}
                    onClose={this.handleConfirmDialogClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    {incomeDialogHeading}
                    <DialogContent>
                        <DialogContentText className={classes.dialog} id="alert-dialog-description">
                            日期:  {this.state.latestIncome['date']} <br />
                            櫃桶: {this.state.latestIncome['drawer']} <br />
                            Cashbox: {this.state.latestIncome['lastCashbox']} <br />
                            一盒盒5蚊: {this.state.latestIncome['fivedollar']} <br />
                            散5蚊: {this.state.latestIncome['otherfivedollar'] == null ? '0' : this.state.latestIncome['otherfivedollar']} <br />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CopyToClipboard text={this.state.incomeMessage}>
                            <Button color="primary">複製</Button>
                        </CopyToClipboard>
                        <Button onClick={this.handleConfirmDialogClose} color="primary">
                            關閉
                        </Button>
                    </DialogActions>
                </Dialog>
                <Typography variant="h5" style={{ marginTop: '10px', marginBottom: '20px', backgroundColor: 'yellow' }}>1. 請將收入確認,輸入5蚊數目並按[輸入至 Excel]</Typography>
                <Typography variant="h6">昨日收鋪時間為：{this.state.close == null ? 'Loading...' : moment(this.state.close).format('YYYY/MM/DD HH:mm')}</Typography>
                <Typography variant="h6" style={{ marginTop: '10px' }}>本日收入為：{this.state.amountTotal == null ? 'Loading...' : '$' + this.state.amountTotal}</Typography>
                <TextField id="standard-number" label="一盒盒五蚊數量"
                    value={this.state.fivedollar}
                    onChange={this.handleChange}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    InputProps={{
                        name: 'fivedollar',
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    margin="normal"
                />
                <TextField id="standard-number" label="散五蚊金額"
                    value={this.state.otherfivedollar}
                    onChange={this.handleChange}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    InputProps={{
                        name: 'otherfivedollar',
                        classes: {
                            root: classes.inputLabel
                        }
                    }}
                    margin="normal"
                /><br />
                {incomeButtonSet}
                <Button variant="contained" className={classes.confirmButton} onClick={this.showIncome}>
                    現時收入狀況
                </Button>
                <hr />
                <Typography variant="h5" style={{ marginTop: '10px', marginBottom: '20px', backgroundColor: 'yellow' }}>2. 請將賣出飲品輸入至<Link target="_blank" href="https://docs.google.com/spreadsheets/d/1CoyhNU8H3yZ3mqqsD04uSQ6GSq5po-zinrMtPYWEvvE/edit?usp=drive_web&ouid=105099034833225912966">Excel</Link></Typography>
                <Typography variant="h6">賣出飲品數目如下：</Typography>
                {this.state.drinks == null ? <Typography variant="h6" style={{ marginTop: '30px' }}>Loading...</Typography> : this.state.drinks.length == 0 ? <Typography variant="h6" style={{ marginTop: '10px' }}>無賣出飲品記錄</Typography> : this.state.drinks.map((object, index) => { return (<Typography key={index} variant="h6" style={{ marginTop: '10px' }}>{object.name + ' : ' + object.amount + '\n'}</Typography>); })}
                {drinkButtonSet}
                <hr />
                <Typography variant="h5" style={{ marginTop: '10px', marginBottom: '20px', backgroundColor: 'yellow' }}>3. 請將賣出貨品輸入至<Link target="_blank" href="https://docs.google.com/spreadsheets/d/1vpP-jr1GI76cWM5w3SzsRVKvpFF2o9i8gL62aqNS6yw/edit#gid=559522420">Excel</Link></Typography>
                <Typography variant="h6">賣出貨品數目如下：</Typography>
                {this.state.goods == null ? <Typography variant="h6" style={{ marginTop: '10px' }}>Loading...</Typography> : this.state.goods.length == 0 ? <Typography variant="h6" style={{ marginTop: '10px' }}>無賣出貨品記錄</Typography> : this.state.goods.map((object, index) => { return (<Typography key={index} variant="h6" style={{ marginTop: '10px' }}>{object.item + ' : ' + object.qty + '\n'}</Typography>); })}
                {goodsButtonSet}
                <hr />
                <Typography variant="h5" style={{ marginTop: '10px', marginBottom: '10px', backgroundColor: 'yellow' }}>收鋪 Check List</Typography>
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxOne} onChange={this.handleCheckboxChange} value="checkboxOne" />
                    }
                    label="關電腦"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxTwo} onChange={this.handleCheckboxChange} value="checkboxTwo" />
                    }
                    label="關播歌電腦"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxThree} onChange={this.handleCheckboxChange} value="checkboxThree" />
                    }
                    label="關冷氣"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxFour} onChange={this.handleCheckboxChange} value="checkboxFour" />
                    }
                    label="關風扇"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxFive} onChange={this.handleCheckboxChange} value="checkboxFive" />
                    }
                    label="關所有電器"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxSix} onChange={this.handleCheckboxChange} value="checkboxSix" />
                    }
                    label="關廁所燈，抽氣扇"
                />
                <FormControlLabel
                    control={
                        <Checkbox color="primary" checked={this.state.checkboxSeven} onChange={this.handleCheckboxChange} value="checkboxSeven" />
                    }
                    label="關窗"
                />
                <br/>
                <Button variant="contained" className={classes.confirmButton} onClick={this.confirmOnClick} disabled={this.state.checkboxOne == false || this.state.checkboxTwo == false || this.state.checkboxThree == false || this.state.checkboxFour == false || this.state.checkboxFive == false || this.state.checkboxSix == false || this.state.checkboxSeven == false}>
                    完成收鋪程序
                </Button>
            </div>
        );
    }
}

TodayClosePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TodayClosePage);