import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography} from '@material-ui/core';
// import { DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
// import moment from 'moment';
import config from 'Config';
// import {CopyToClipboard} from 'react-copy-to-clipboard';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    progress: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        flex: '1',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    confirmButton: {
        marginTop: '10px',
        marginRight: '10px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px',
        color: 'black'
    },
    textField: {
        marginRight: '20px'
    }
});

class WeeklyClosePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weeklyIncome: 0
        };
        this.showIncome = this.showIncome.bind(this);
    }

    componentDidMount() {
        this.showIncome();
    }

    showIncome() {
        axios.get(config.api+'/income/latestIncome').then((income) => {
            this.setState({weeklyIncome: income.data.lastCashbox});
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{margin: '5px'}}>
                <Typography className={classes.inputLabel}>上星期總收入:{this.state.weeklyIncome}</Typography>
            </div>
        );
    }
}

WeeklyClosePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WeeklyClosePage);