import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import { Icon } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CakeIcon from '@material-ui/icons/Cake';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PeopleIcon from '@material-ui/icons/People';
import AdjustIcon from '@material-ui/icons/Adjust';
import MoneyIcon from '@material-ui/icons/Money';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HelpIcon from '@material-ui/icons/Help';
import ListItemText from '@material-ui/core/ListItemText';
import ChangeMoneyPage from './ChangeMoneyPage';
import DrinkPage from './DrinkPage';
import FreePlayPage from './FreePlayPage';
import GoodsPage from './GoodsPage';
import ExpensePage from './ExpensePage';
import OthersIncomePage from './OthersIncomePage';
import CurrentMoneyLogPage from './CurrentMoneyLogPage';
import TodayClosePage from './TodayClosePage';
import CheckinoutPage from './CheckinoutPage';
import LeaguePage from './LeaguePage';
import BarcodePage from './BarcodePage';
import NewMemberPage from './NewMemberPage';
import CheckMemberPage from './CheckMemberPage';
import CheckGoodsPage from './CheckGoodsPage';
import WeeklyClosePage from './WeeklyClosePage';
import NoticeBoardPage from './NoticeBoardPage';
import OrderItemPage from './OrderItemPage';
import { isMobile } from 'react-device-detect';
import config from 'Config';
import PasswordDialog from './PasswordDialog';
import axios from 'axios';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'yellow',
        color: 'black',
        fontSize: '20px',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 10,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    versionText: {
        position: 'absolute',
        bottom: 5,
        left: 5,
        fontSize: '8px',
        display: 'flex',
        flexDirection: 'column'
    }
});

class MiniDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.confirmPasswordDialoghandler_closeshop = this.confirmPasswordDialoghandler_closeshop.bind(this);
        this.confirmPasswordDialoghandler_main = this.confirmPasswordDialoghandler_main.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isInD8 = this.isInD8.bind(this);
        this.loginButtonClicked = this.loginButtonClicked.bind(this);
        this.loginPressed = this.loginPressed.bind(this);
        this.logoutButtonClicked = this.logoutButtonClicked.bind(this);
        this.state = {
            open: isMobile ? false : true,
            curTime: new Date().toLocaleString(),
            selectedMenu: '',
            selectedMenuId: -1,
            passwordDialogOpen: false,
            passwordDialogOpen_closeshop: false,
            loggedIn: false,
            showMainLogin: true,
            password: '',
            topBarloggedIn: false
        };
    }

    componentDidMount() {
        setInterval(function () {
            this.setState({
                curTime: new Date().toLocaleString()
            });
        }.bind(this), 1000);
        this.isInD8();
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
    }

    isInD8() {
        axios.get(config.api + '/password/pass')
            .then((response) => {
                if (response.data.pass == 1) {
                    this.setState({
                        showMainLogin: false,
                        selectedMenu: 'Notice',
                        selectedMenuId: 15
                    }, () => { console.log('logged in'); });
                } else {
                    this.setState({ showMainLogin: true }, () => { console.log('not logged in'); });
                }
            });
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    }

    handleDrawerClose() {
        this.setState({ open: false });
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false,
            passwordDialogOpen_closeshop: false,
        });
    }

    confirmPasswordDialoghandler(name, role) {
        if (role == 'boss') {
            this.setState({
                selectedMenu: '現時數簿',
                selectedMenuId: 7,
                passwordDialogOpen: false,
            });
        }
    }

    confirmPasswordDialoghandler_closeshop(name, role) {
        if (role == 'boss') {
            this.setState({
                selectedMenu: '收鋪',
                selectedMenuId: 8,
                passwordDialogOpen_closeshop: false,
                loggedInName: name
            });
        }
    }

    confirmPasswordDialoghandler_main() {
        this.setState({
            loggedIn: true,
            selectedMenu: 'Notice',
            selectedMenuId: 15
        });
    }

    loginPressed(e) {
        if (e.key === 'Enter') {
            this.loginButtonClicked();
        }
    }

    loginButtonClicked() {
        axios.get(config.api + '/password?password=' + this.state.password).then((response) => {
            if (response.data !== undefined && response.data !== null) {
                this.setState({
                    topBarloggedIn: true,
                    user: response.data.user
                });
                axios.post(config.api + '/log/login-log', { username: response.data.user });
            }
        });
    }

    logoutButtonClicked() {
        this.setState({
            topBarloggedIn: false,
            user: '',
            password: ''
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    renderContent() {
        switch (this.state.selectedMenuId) {
        case 0:
            return (
                <ChangeMoneyPage classes={this.classes} user={this.state.user} />
            );
        case 1:
            return (
                <FreePlayPage user={this.state.user} />
            );
        case 2:
            return (
                <DrinkPage user={this.state.user} />
            );
        case 3:
            return (
                <GoodsPage user={this.state.user} />
            );
        case 4:
            return (
                <OthersIncomePage user={this.state.user} />
            );
        case 5:
            return (
                <ExpensePage user={this.state.user} />
            );
        case 6:
            return (
                <LeaguePage />
            );
        case 7:
            return (
                <CurrentMoneyLogPage />
            );
        case 8:
            return (
                <TodayClosePage />
            );
        case 9:
            return <BarcodePage loggedIn={this.state.topBarloggedIn} user={this.state.user} />;
        case 10:
            return <NewMemberPage />;
        case 11:
            return <CheckMemberPage />;
        case 12:
            return <CheckinoutPage />;
        case 13:
            return <CheckGoodsPage />;
        case 14:
            return <WeeklyClosePage />;
        case 15:
            return <NoticeBoardPage />;
        case 16:
            return <OrderItemPage />;
        }
    }

    handleClick(clicked) {
        if (isMobile) {
            this.handleDrawerClose();
        }
        switch (clicked) {
        case 0:
            this.setState({
                selectedMenu: '唱錢',
                selectedMenuId: 0,
            });
            break;
        case 1:
            this.setState({
                selectedMenu: '任掟',
                selectedMenuId: 1,
            });
            break;
        case 2:
            this.setState({
                selectedMenu: '飲品',
                selectedMenuId: 2,
            });
            break;
        case 3:
            this.setState({
                selectedMenu: '貨品',
                selectedMenuId: 3,
            });
            break;
        case 4:
            this.setState({
                selectedMenu: '其他收入',
                selectedMenuId: 4,
            });
            break;
        case 5:
            this.setState({
                selectedMenu: '支出',
                selectedMenuId: 5,
            });
            break;
        case 6:
            this.setState({
                selectedMenu: '比賽',
                selectedMenuId: 6,
            });
            break;
        case 7:
            this.setState({ passwordDialogOpen: true });
            break;
        case 8:
            this.setState({ passwordDialogOpen_closeshop: true });
            break;
        case 9:
            this.setState({
                selectedMenu: 'Cashier',
                selectedMenuId: 9,
            });
            break;
        case 10:
            this.setState({
                selectedMenu: '新增會員',
                selectedMenuId: 10,
            });
            break;
        case 11:
            this.setState({
                selectedMenu: '查詢/更新會員',
                selectedMenuId: 11,
            });
            break;
        case 12:
            this.setState({
                selectedMenu: 'Check-in / Check-out',
                selectedMenuId: 12,
            });
            break;
        case 13:
            this.setState({
                selectedMenu: '貨品查詢',
                selectedMenuId: 13,
            });
            break;
        case 14:
            this.setState({
                selectedMenu: '星期結算',
                selectedMenuId: 14
            });
            break;
        case 15:
            this.setState({
                selectedMenu: 'Notice',
                selectedMenuId: 15
            });
            break;
        case 16:
            this.setState({
                selectedMenu: '訂貨',
                selectedMenuId: 16
            });
            break;
        }
    }

    render() {
        const { classes, theme } = this.props;
        return (
            <div className={classes.root}>
                {this.state.showMainLogin == true ? (
                    <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler_main} open={!this.state.loggedIn} />
                ) : null}
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler_closeshop} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen_closeshop} />
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar disableGutters={!this.state.open}>
                        <IconButton
                            color="primary"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: this.state.open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton onClick={this.handleDrawerClose}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: !this.state.open,
                            })}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                        <Typography variant="h5" color="inherit" noWrap>
                            {this.state.selectedMenu}
                        </Typography>
                        <div style={{ marginLeft: 'auto' }}>
                            {this.state.topBarloggedIn == false &&
                                <div>
                                    <TextField
                                        edge="end"
                                        placeholder="Password"
                                        type="password"
                                        margin="dense"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        onKeyDown={this.loginPressed}
                                        inputProps={{
                                            name: 'password',
                                        }}
                                    />
                                    <Button edge="end" variant="contained" style={{ marginLeft: '5px', backgroundColor: 'yellow' }} onClick={this.loginButtonClicked}>
                                        登入
                                    </Button>
                                </div>}
                            {this.state.topBarloggedIn == true &&
                                <div style={{ float: 'right' }}>
                                    <span style={{ fontSize: '14px' }}>{this.state.user} 已登入</span>
                                    <Button variant="contained" style={{ marginLeft: '5px', backgroundColor: 'yellow' }} onClick={this.logoutButtonClicked}>
                                        登出
                                    </Button>
                                </div>}
                        </div>

                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbar}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    D8 DOJO & Workshop
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {this.state.curTime}
                                </Typography>
                            </Grid>
                        </Grid>

                    </div>
                    <Divider />
                    <List>
                        <ListItem button key='Cashier' onClick={() => { this.handleClick(9); }}>
                            <ListItemIcon><Icon className={classNames(classes.icon, 'fa fa-barcode')} /></ListItemIcon>
                            <ListItemText primary='Cashier' />
                        </ListItem>
                        <ListItem button key='Notice Board' onClick={() => { this.handleClick(15); }}>
                            <ListItemIcon><Icon className={classNames(classes.icon, 'fa fa-flag')} /></ListItemIcon>
                            <ListItemText primary='Notice Board' />
                        </ListItem>
                        <ListItem button key='唱錢' onClick={() => { this.handleClick(0); }}>
                            <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                            <ListItemText primary='唱錢' />
                        </ListItem>
                        <ListItem button key='任掟' onClick={() => { this.handleClick(1); }}>
                            <ListItemIcon><PeopleIcon /></ListItemIcon>
                            <ListItemText primary='任掟' />
                        </ListItem>
                        <ListItem button key='飲品' onClick={() => { this.handleClick(2); }}>
                            <ListItemIcon><LocalDrinkIcon /></ListItemIcon>
                            <ListItemText primary='飲品' />
                        </ListItem>
                        <ListItem button key='貨品' onClick={() => { this.handleClick(3); }}>
                            <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                            <ListItemText primary='貨品' />
                        </ListItem>
                        <ListItem button key='CheckGoods' onClick={() => { this.handleClick(13); }}>
                            <ListItemIcon><HelpIcon /></ListItemIcon>
                            <ListItemText primary='貨品查詢' />
                        </ListItem>
                        <ListItem button key='order' onClick={() => { this.handleClick(16); }}>
                            <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                            <ListItemText primary='訂貨' />
                        </ListItem>
                        <ListItem button key='其他收入' onClick={() => { this.handleClick(4); }}>
                            <ListItemIcon><MoneyIcon /></ListItemIcon>
                            <ListItemText primary='其他收入' />
                        </ListItem>
                        <ListItem button key='支出' onClick={() => { this.handleClick(5); }}>
                            <ListItemIcon><CakeIcon /></ListItemIcon>
                            <ListItemText primary='支出' />
                        </ListItem>
                        <ListItem button key='比賽' onClick={() => { this.handleClick(6); }}>
                            <ListItemIcon><AdjustIcon /></ListItemIcon>
                            <ListItemText primary='比賽' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button key='現時數簿' onClick={() => { this.handleClick(7); }}>
                            <ListItemIcon><WorkOutlineIcon /></ListItemIcon>
                            <ListItemText primary='現時數簿' />
                        </ListItem>
                        <ListItem button key='收鋪' onClick={() => { this.handleClick(8); }}>
                            <ListItemIcon><WorkOffIcon /></ListItemIcon>
                            <ListItemText primary='收鋪' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button key='新增會員' onClick={() => { this.handleClick(10); }}>
                            <ListItemIcon><HowToRegIcon /></ListItemIcon>
                            <ListItemText primary='新增會員' />
                        </ListItem>
                        <ListItem button key='查詢/更新會員' onClick={() => { this.handleClick(11); }}>
                            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                            <ListItemText primary='查詢/更新會員' />
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem button key='Checkinout' onClick={() => { this.handleClick(12); }}>
                            <ListItemIcon><HowToRegIcon /></ListItemIcon>
                            <ListItemText primary='Check-in / Check-out' />
                        </ListItem>
                        <ListItem button key='Weekly' onClick={() => { this.handleClick(14); }}>
                            <ListItemIcon><HowToRegIcon /></ListItemIcon>
                            <ListItemText primary='星期結算' />
                        </ListItem>
                    </List>
                    <Typography className={classes.versionText}>Version: {config.version}</Typography>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {this.renderContent()}
                </main>
            </div>
        );
    }
}

MiniDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);