import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Grid, Card, CardContent, InputAdornment } from '@material-ui/core';
import config from 'Config';
import PasswordDialog from './PasswordDialog';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize: {
        fontSize: 50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
    discountButton: {
        marginTop: '20px',
        fontSize: '10px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class GoodsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            barcode: '',
            barcodeError: false,
            selectedCategory: 'Tip',
            selectedItem: '',
            selectedItemsList: [],
            itemsJSON: {},
            items: [],
            qty: 1,
            price: 0,
            originalPrice: 0,
            message: '',
            total: 0,
            receive: 0,
            change: 0,
            cashierRemark: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleChangeGoods = this.handleChangeGoods.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
        this.requestItems = this.requestItems.bind(this);
        this.sevenFiveOffClicked = this.sevenFiveOffClicked.bind(this);
        this.sevenOffClicked = this.sevenOffClicked.bind(this);
        this.eightOffClicked = this.eightOffClicked.bind(this);
        this.eightFiveOffClicked = this.eightFiveOffClicked.bind(this);
        this.nineOffClicked = this.nineOffClicked.bind(this);
        this.originalPriceClicked = this.originalPriceClicked.bind(this);
        this.confirmPasswordDialoghandler = this.confirmPasswordDialoghandler.bind(this);
        this.closePasswordDialoghandler = this.closePasswordDialoghandler.bind(this);
        this.handleAddingClicked = this.handleAddingClicked.bind(this);
        this.addGoodsToList = this.addGoodsToList.bind(this);
        this.removeItemInList = this.removeItemInList.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.handleCardChange = this.handleCardChange.bind(this);
        this.calculateChange = this.calculateChange.bind(this);
        this.handleMoneyChange = this.handleMoneyChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        this.requestItems('Tip');
    }

    handleCategoryChange(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.requestItems(this.state.selectedCategory);
        });
    }

    handleChangeGoods(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            console.log(event.target.value);
            this.setState({
                price: this.state.itemsJSON.filter(e=>e['_id'] == event.target.value)[0].price,
                originalPrice: this.state.itemsJSON.filter(e=>e['_id'] == event.target.value)[0].price,
                // cashierRemark: this.state.itemsJSON[this.state.selectedItem].remark == undefined ? '' : this.state.itemsJSON[this.state.selectedItem].remark,
            });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    requestItems(category) {
        this.setState({cashierRemark: '', price: 0, originalPrice: 0});
        // axios.get(config.api + '/goods?' + 'cat=' + category)
        //     .then((response) => {
        //         var keys = [];
        //         this.setState({ itemsJSON: response.data });
        //         for (var key in response.data) {
        //             keys.push(key);
        //         }
        //         this.setState({ items: keys });
        //     });
        axios.get(config.api + '/inventory?category=' + category).then((response) => {
            this.setState({ itemsJSON: response.data.filter(e=>e.isDeleted == false) });
        });
    }

    handleConfirmClicked() {
        if (this.state.user != '' && this.state.user != undefined) {
            this.confirmPasswordDialoghandler(this.state.user);
        } else {
            this.setState({
                passwordDialogOpen: true,
            });
        }
    }

    sevenOffClicked() {
        this.setState({ price: (this.state.originalPrice * 0.7).toFixed(1) });
    }

    sevenFiveOffClicked() {
        this.setState({ price: (this.state.originalPrice * 0.75).toFixed(1) });
    }

    eightOffClicked() {
        this.setState({ price: (this.state.originalPrice * 0.8).toFixed(1) });
    }

    eightFiveOffClicked() {
        this.setState({ price: (this.state.originalPrice * 0.85).toFixed(1) });
    }

    nineOffClicked() {
        this.setState({ price: (this.state.originalPrice * 0.9).toFixed(1) });
    }

    originalPriceClicked() {
        this.setState({ price: this.state.originalPrice });
    }

    closePasswordDialoghandler() {
        this.setState({
            passwordDialogOpen: false
        });
    }

    confirmPasswordDialoghandler(user) {
        this.state.selectedItemsList.map((e, i) => {
            axios.post(config.api + '/log', {
                user,
                item: e.item,
                category: '貨品',
                categoryId: 6,
                goods_cat: e.goods_cat,
                amount: e.price,
                goods_itemId: e.itemId,
                remark: this.state.remark,
                isDeleted: 0,
                qty: 1
            })
                .then(() => {
                    this.setState({
                        passwordDialogOpen: false
                    });
                    if (i == this.state.selectedItemsList.length - 1) {
                        this.setState({
                            selectedItemsList: [],
                            selectedItem: '',
                            price: 0,
                            originalPrice: 0,
                            message: '貨品已輸入。'
                        });
                    }
                })
                .catch((error) => console.log(error));
        });

    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ user: newProps.user });
    }

    addGoodsToList(goods) {
        this.setState({
            selectedItemsList: [...this.state.selectedItemsList, goods]
        }, () => {
            this.calculateTotal();
        });
    }

    handleAddingClicked() {
        const goods = {
            item: this.state.itemsJSON.filter(e=>e._id == this.state.selectedItem)[0].name + ' - ' + this.state.itemsJSON.filter(e=>e._id == this.state.selectedItem)[0].color,
            category: '貨品',
            categoryId: 6,
            goods_cat: this.state.selectedCategory,
            price: this.state.price,
            qty: this.state.qty,
            itemId: this.state.selectedItem
        };
        this.addGoodsToList(goods);
    }

    removeItemInList(index) {
        var list = this.state.selectedItemsList;
        if (list.length == 1) {
            list = [];
        } else {
            if (index == 0) {
                list.splice(0, 1);
            } else {
                list.splice(index, index);
            }
        }
        this.setState({
            selectedItemsList: list
        }, () => {
            this.calculateTotal();
        });

    }

    calculateTotal() {
        var list = this.state.selectedItemsList;
        var total = 0;
        list.map((e) => {
            return (total += parseInt(e.price));
        });
        this.setState({ total }, () => {
            this.calculateChange();
        });
    }

    handleCardChange(event) {
        var currentResult = this.state.selectedItemsList;
        var currentData = this.state.selectedItemsList[parseInt(event.target.name)];
        currentData.price = event.target.value;
        currentResult[parseInt(event.target.name)] = currentData;
        this.setState({
            selectedItemsList: currentResult
        }, () => {
            this.calculateTotal();
        });
    }

    calculateChange() {
        this.setState({ change: this.state.receive - this.state.total });
    }

    handleMoneyChange(event) {
        this.setState({ receive: event.target.value }, () => {
            this.calculateChange();
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.confirmHandler();
        }
    }

    confirmHandler() {
        axios.get(config.api + '/goods/barcode?barcode=' + this.state.barcode)
            .then((responseBarcode) => {
                if (responseBarcode.data.success == 0) {
                    this.setState({barcode: '', barcodeError: true});
                } else {
                    axios.get(config.api + '/goods?' + 'cat=' + responseBarcode.data[0].itemCategory)
                        .then((response) => {
                            var keys = [];
                            this.setState({ itemsJSON: response.data });
                            for (var key in response.data) {
                                keys.push(key);
                            }
                            if (responseBarcode.data.length > 0) {
                                this.setState({
                                    barcodeError: false,
                                    items: keys,
                                    selectedItem: responseBarcode.data[0].name,
                                    selectedCategory: responseBarcode.data[0].itemCategory,
                                    price: responseBarcode.data[0].price,
                                    originalPrice: responseBarcode.data[0].price,
                                    qty: 1
                                });
                            }
                        });
                }
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <PasswordDialog confirmHandler={this.confirmPasswordDialoghandler} closeHandler={this.closePasswordDialoghandler} open={this.state.passwordDialogOpen} />
                <TextField
                    id="outlined-full-width"
                    label="Barcode"
                    style={{ margin: 8 }}
                    fullWidth
                    error={this.state.barcodeError}
                    autoFocus
                    margin="normal"
                    variant="outlined"
                    value={this.state.barcode}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    // inputRef={this.barcodeInput}
                    inputProps={{
                        name: 'barcode',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>類別</InputLabel>
                        <Select className={classes.select} value={this.state.selectedCategory} onChange={this.handleCategoryChange}
                            inputProps={{
                                name: 'selectedCategory',
                            }}>
                            <MenuItem className={classes.menuItem} value="Tip">鏢頭</MenuItem>
                            <MenuItem className={classes.menuItem} value="Shaft">鏢桿</MenuItem>
                            <MenuItem className={classes.menuItem} value="Flight">鏢翼</MenuItem>
                            <MenuItem className={classes.menuItem} value="Barrel">鏢身</MenuItem>
                            <MenuItem className={classes.menuItem} value="Others">其他</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>貨品</InputLabel>
                        <Select className={classes.select} value={this.state.selectedItem} onChange={this.handleChangeGoods}
                            inputProps={{
                                name: 'selectedItem',
                            }}>
                            {this.state.itemsJSON.length > 0 ? this.state.itemsJSON.map((e, i) => {
                                return <MenuItem className={classes.menuItem} value={e['_id']} key={i}>{e.name} - {e.color} ({e.qty})</MenuItem>;
                            }) : <MenuItem className={classes.menuItem} value="noitem">無可選項目</MenuItem>}
                        </Select>
                    </FormControl>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <TextField className={classes.formControl}
                                id="standard-number-2"
                                label="價錢"
                                value={this.state.price}
                                onChange={this.handleChange}
                                type="number"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputLabel
                                    }
                                }}
                                InputProps={{
                                    name: 'price',
                                    classes: {
                                        root: classes.inputLabel
                                    }
                                }}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    
                    <Typography variant="h5" style={{color: 'red'}}>{this.state.cashierRemark}</Typography>
  
                    <Grid container spacing={8}>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.sevenOffClicked}>
                                7 折
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.sevenFiveOffClicked}>
                                75 折
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.eightOffClicked}>
                                8 折 (學生價)
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.eightFiveOffClicked}>
                                85 折
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.nineOffClicked}>
                                9 折
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" className={classes.discountButton} onClick={this.originalPriceClicked}>
                                原價
                            </Button>
                        </Grid>
                    </Grid>
                    <TextField className={classes.formControl}
                        id="remark"
                        label="備註"
                        value={this.state.remark}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        InputProps={{
                            name: 'remark',
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                        margin="normal"
                    />
                </form>
                <Button variant="contained" className={classes.button} onClick={this.handleAddingClicked}>
                    加入清單
                </Button>
                {this.state.selectedItemsList.map((e, i) => {
                    return (
                        <Card key={i} style={{ minWidth: '100%', margin: 8, marginTop: '20px' }}>
                            <CardContent style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <Typography variant="h6"> {e.item} </Typography>
                                <TextField
                                    id="price"
                                    label="價錢"
                                    style={{ marginTop: '20px' }}
                                    InputProps={{
                                        name: String(i),
                                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                    }}
                                    value={e.price}
                                    // defaultValue={item.data.price}
                                    onChange={this.handleCardChange}
                                />
                            </CardContent>
                            <Button key={i} onClick={() => { this.removeItemInList(i); }}>移除</Button>
                        </Card>);
                })}
                <TextField
                    id="standard-name"
                    label="總計"
                    disabled
                    value={this.state.total}
                    className={classes.textField}
                    InputProps={{
                        name: 'total',
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                    }}
                    margin="normal"
                />
                <TextField
                    id="receive"
                    label="收取"
                    value={this.state.receive}
                    onChange={this.handleMoneyChange}
                    className={classes.textField}
                    InputProps={{
                        name: 'receive',
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                    }}
                    disabled={this.state.total <= 0}
                    margin="normal"
                />
                <TextField
                    id="change"
                    label="找續"
                    disabled
                    value={this.state.change}
                    className={classes.textField}
                    InputProps={{
                        name: 'change',
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                    }}
                    margin="normal"
                />
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>
                    確定
                </Button>
                <Typography variant="h6" style={{ marginTop: '30px' }}> {this.state.message}</Typography>

            </div>

        );
    }
}

GoodsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoodsPage);
