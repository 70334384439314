import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Button, Snackbar, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import config from 'Config';

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: '100%'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    button: {
        backgroundColor: 'yellow',
        marginTop: '10px',
    }
});

class CheckMemberPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            barcode: '',
            id: '',
            name: '',
            telephone: '',
            email: '',
            memberRank: '',
            snackBarOpen: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.checkMember = this.checkMember.bind(this);
        this.handleConfirmClicked = this.handleConfirmClicked.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.checkMember();
        }
    }

    handleConfirmClicked() {
        axios.put(config.api + '/member?id=' + this.state.id, {
            name: this.state.name,
            role: this.state.memberRank,
            telephone: this.state.telephone,
            email: this.state.email
        })
            .then((response) => {
                if (response.status == 200) {
                    this.setState({id:'',barcode:'',telephone:'',name:'',memberRank:'',email:'',snackBarOpen: true, message: '更新完成。'}, () => {
                        setTimeout(() => {this.setState({snackBarOpen: false});}, 2000);
                    });
                }
            });
    }

    checkMember() {
        axios.get(config.api + '/member/search?q=' + this.state.barcode)
            .then((response) => {
                if (response.data == null) {
                    this.setState({
                        snackBarOpen: true,
                        message: '找不到會員。',
                        id: '',
                        name: '',
                        memberRank: '',
                        telephone: '',
                        email: ''
                    });
                    return;
                }
                this.setState({
                    id: response.data.id,
                    name: response.data.name,
                    memberRank: response.data.rank,
                    telephone: response.data.telephone,
                    email: response.data.email
                });
            })
            .catch((err) => {
                this.setState({
                    snackBarOpen: true,
                    message: err,
                    id: '',
                    name: '',
                    memberRank: '',
                    telephone: '',
                    email: ''
                });
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackBarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />
                <TextField
                    id="outlined-full-width"
                    label="Search"
                    style={{ margin: 8 }}
                    fullWidth
                    autoFocus
                    margin="normal"
                    variant="outlined"
                    value={this.state.barcode}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    inputProps={{
                        name: 'barcode',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="id"
                    label="會員編號"
                    disabled
                    className={classes.textField}
                    value={this.state.id}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'id'
                    }}
                    margin="normal"
                />
                <TextField
                    id="name"
                    label="姓名"
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'name'
                    }}
                    margin="normal"
                />
                <TextField
                    id="telephone"
                    label="電話號碼"
                    className={classes.textField}
                    value={this.state.telephone}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'telephone'
                    }}
                    margin="normal"
                />
                <TextField
                    id="email"
                    label="Email"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'email'
                    }}
                    margin="normal"
                />
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.inputLabel}>會員等級</InputLabel>
                    <Select className={classes.select} value={this.state.memberRank} onChange={this.handleChange}
                        inputProps={{
                            name: 'memberRank',
                        }}>
                        <MenuItem disabled value="boss">D8</MenuItem>
                        <MenuItem disabled value="staff">D8 Staff</MenuItem>
                        <MenuItem value="VIP">高級會員</MenuItem>
                        <MenuItem value="normal">普通會員</MenuItem>
                        <MenuItem value="point_card">儲分卡</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" className={classes.button} onClick={this.handleConfirmClicked}>
                        確定
                </Button>
            </div>
        );
    }
}


CheckMemberPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckMemberPage);