import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, CardActions, Button, TextField, IconButton } from '@material-ui/core';
import { DialogContent, DialogActions, Dialog, DialogTitle } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
// import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
// import moment from 'moment';
import config from 'Config';
// import {CopyToClipboard} from 'react-copy-to-clipboard';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    progress: {
        margin: theme.spacing.unit,
        marginTop: '30px',
        flex: '1',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    menuItem: {
        fontSize: '18px'
    },
    select: {
        fontSize: '18px'
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    confirmButton: {
        marginTop: '10px',
        marginRight: '10px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow'
    },
    dialog: {
        fontSize: '18px',
        color: 'black'
    },
    textField: {
        marginRight: '20px'
    }
});

class NoticeBoardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notices: [],
            open: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.addNotice = this.addNotice.bind(this);
        this.loadData = this.loadData.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.upVote = this.upVote.bind(this);
        this.downVote = this.downVote.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleOpen() {
        this.setState({open: false});
    }

    addNotice() {
        axios.post(config.api + '/notice', {user: this.state.name, message: this.state.message}).then(() => {
            this.loadData();
        });
    }

    loadData() {
        axios.get(config.api + '/notice').then((response) => {
            this.setState({
                notices: response.data,
                message: '',
                name: ''
            });
        });
    }

    deleteData(id) {
        console.log(id);
        axios.delete(config.api + '/notice', {data:{id}}).then(() => {
            this.loadData();
        });
    }

    upVote(notice) {
        const tempScore = notice.score;
        var score = parseInt(tempScore);
        if (tempScore == undefined) {
            score = 1;
        } else {
            score = score + 1;
        }
        axios.put(config.api + '/notice',  {id: notice._id, score}).then(() => {
            this.loadData();
        });
    }

    downVote(notice) {
        var score = notice.score;
        if (score == undefined) {
            score = 1;
        } else {
            score = score - 1;
        }
        axios.put(config.api + '/notice',  {id: notice._id, score}).then(() => {
            this.loadData();
        });
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.state.open} onClose={this.handleOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">New Notice</DialogTitle>
                    <DialogContent>
                        <TextField label="Name" value={this.state.name} onChange={this.handleChange}
                            InputProps={{
                                name: 'name'
                            }}
                            autoFocus
                            margin="dense"
                            fullWidth
                        />
                        <TextField label="Message" value={this.state.message} onChange={this.handleChange}
                            InputProps={{
                                name: 'message'
                            }}
                            margin="dense"
                            fullWidth
                            multiline
                            rows="4"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOpen} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.addNotice} color="primary">
                            確定
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{ margin: '5px' }}>
                    <Typography className={classes.inputLabel} style={{ fontWeight: '800'}}>Notice Board</Typography>
                </div>
                <div style={{ margin: '5px'}}>
                    <Button size="small" color="primary" onClick={() => this.setState({open: true})}>+New</Button>
                    <Button size="small" color="primary" onClick={this.loadData}>Refresh</Button>
                </div>
                <div>
                    {this.state.notices.map((e, i) => {
                        return (
                            <Card key={i} className={classes.root} style={{margin: '10px'}}>
                                <CardContent style={{width: '100%'}}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {e.createdBy}
                                    </Typography>
                                    <Typography variant="h5" component="h2" style={{whiteSpace: 'pre-line'}}>
                                        {e.message}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <IconButton size="small" onClick={() => this.upVote(e)}>
                                        <ThumbUpAltIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => this.downVote(e)}>
                                        <ThumbDownIcon />
                                    </IconButton>
                                    <Typography variant="h6">{e.score}</Typography>
                                    <Button size="small" onClick={() => this.deleteData(e._id)}>Delete</Button>
                                </CardActions>
                            </Card>
                        );
                    })}
                    {this.state.notices.length == 0 &&
                        <div style={{ margin: '5px' }}>
                            <Typography className={classes.inputLabel}>No Notice</Typography>
                        </div>
                    }
                </div>
            </div>

        );
    }
}

NoticeBoardPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoticeBoardPage);