import React, { Component } from 'react';
// import MainPage from './MainPage';
import yellow from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'; 
import MainPage from './MainPage';

const theme = createMuiTheme({
    palette: {
        primary: yellow
    },
    MuiSelect: {
        root: {
            '&$focused': {
                color: yellow
            }
        }
    },
    typography: {
        'fontFamily': '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        'fontSize': 20,
        'fontWeightLight': 300,
        'fontWeightRegular': 400,
        'fontWeightMedium': 500
    }
});

export default class App extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <MainPage />
            </MuiThemeProvider>
        );
    }
}