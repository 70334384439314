import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import config from 'Config';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    bodyText: {
        fontSize: '28px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        margin: theme.spacing.unit,
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize:{
        fontSize:50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class CurrentMoneyLogDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            data: {},
            loading: true,
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveData = this.saveData.bind(this);
        this.deleteData = this.deleteData.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.open != this.state.open) {
            this.setState({
                open: props.open
            });
        }
        if (props.data != this.state.data) {
            this.setState({
                data: props.data
            });
        }
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleChange(event) {
        var newData = this.state.data;
        const selected = event.target.name;
        newData[selected] = event.target.value;
        this.setState({data: newData});
    }

    saveData() {
        axios.put(config.api+'/log',{
            id: this.state.data._id,
            item: this.state.data.item,
            amount: this.state.data.amount,
            remark: this.state.data.remark
        })
            .then(() => {
                this.props.closeHandler();
            });
    }

    deleteData() {
        axios.delete(config.api+'/log/' + this.state.data._id)
            .then(() => {
                this.props.closeHandler();
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Dialog
                    open={this.state.open}
                    onClose={this.props.closeHandler}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{fontSize:'20px'}}>Edit</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="item"
                            label="Item"
                            fullWidth
                            disabled
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'item',
                                readOnly: true,
                            }}
                            value={this.state.data.item}
                        />
                        <TextField
                            margin="dense"
                            id="amount"
                            label="Amount"
                            type="number"
                            fullWidth
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'amount',
                            }}
                            value={this.state.data.amount}
                        />
                        <TextField
                            margin="dense"
                            id="remark"
                            label="Remark"
                            fullWidth
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'remark',
                            }}
                            value={this.state.data.remark}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.saveData} color="primary">
                            Confrim
                        </Button>
                        <Button onClick={this.deleteData} color="primary">
                            Delete
                        </Button>
                        <Button onClick={this.props.closeHandler} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CurrentMoneyLogDialog.propTypes = {
    open: PropTypes.bool,
    closeHandler: PropTypes.func,
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.Object
};
CurrentMoneyLogDialog.defaultProps = { open: false };

export default withStyles(styles)(CurrentMoneyLogDialog);