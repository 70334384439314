import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import config from 'Config';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: '100%',
    },
    inputLabel: {
        fontSize: '17px',
        margin: '-2px'
    },
    select: {
        fontSize: '18px'
    },
    menuItem: {
        fontSize: '18px'
    },
    textField: {
        minWidth: '100%',
        display: 'flex',
        fontSize: '20px',
    },
    resize:{
        fontSize:50
    },
    button: {
        marginTop: '30px',
        fontSize: '18px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
    discountButton: {
        marginTop: '20px',
        fontSize: '10px',
        flex: '1',
        backgroundColor: 'yellow',
        minWidth: '100%'
    },
});

class CheckGoodsPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'Tip',
            selectedItem: '',
            itemsJSON:{},
            items: [],
            details: '',
            d8details: '',
            mldetails: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleChangeGoods = this.handleChangeGoods.bind(this);
        this.requestItems = this.requestItems.bind(this);
    }

    componentDidMount() {
        this.requestItems('Tip');
    }

    handleCategoryChange(event) {
        this.setState({ [event.target.name]: event.target.value },() => {
            this.requestItems(this.state.selectedCategory);
        });
    }

    handleChangeGoods(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            var selectedItem = this.state.itemsJSON[this.state.selectedItem];
            var details = '價錢: $' + selectedItem.details.price + '\n';
            this.setState({details});
            if (selectedItem['d8'] != undefined) {
                this.setState({d8details: 'D8數量: ' + selectedItem['d8']['qty']});
            }else {
                this.setState({d8details: ''});
            }
            if (selectedItem['meilok'] != undefined) {
                this.setState({mldetails: '美樂數量: ' + selectedItem['meilok']['qty']});
            }else {
                this.setState({mldetails: ''});
            }
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    requestItems(category) {
        axios.get(config.api+'/goods/stocks?' + 'cat=' + category)
            .then((response) => {
                var keys = [];
                this.setState({itemsJSON: response.data});
                for (var key in response.data) {
                    keys.push(key);
                }
                this.setState({items: keys});
            });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({user: newProps.user});
    }

    render() {
        const { classes } = this.props;
        return(
            <div>
                <form className={classes.root} autoComplete="off">
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>類別</InputLabel>
                        <Select className={classes.select} value={this.state.selectedCategory} onChange={this.handleCategoryChange}
                            inputProps={{
                                name: 'selectedCategory',
                            }}>
                            <MenuItem className={classes.menuItem} value="Tip">鏢頭</MenuItem>
                            <MenuItem className={classes.menuItem} value="Shaft">鏢桿</MenuItem>
                            <MenuItem className={classes.menuItem} value="Flight">鏢翼</MenuItem>
                            <MenuItem className={classes.menuItem} value="Barrel">鏢身</MenuItem>
                            <MenuItem className={classes.menuItem} value="Others">其他</MenuItem>
                        </Select>
                    </FormControl>        
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel}>貨品</InputLabel>
                        <Select className={classes.select} value={this.state.selectedItem} onChange={this.handleChangeGoods}
                            inputProps={{
                                name: 'selectedItem',
                            }}>
                            {this.state.items.length > 0 ? this.state.items.map((e, i) => {
                                return <MenuItem className={classes.menuItem} value={e} key={i}>{e}</MenuItem>;
                            }) : <MenuItem className={classes.menuItem} value="noitem">無相關項目</MenuItem>}
                        </Select>
                    </FormControl>
                </form>
                <Typography variant="h6" style={{marginTop: '30px'}}> {this.state.details}</Typography>
                <Typography variant="h6" style={{marginTop: '30px'}}> {this.state.d8details}</Typography>
                <Typography variant="h6" style={{marginTop: '30px'}}> {this.state.mldetails}</Typography>
            </div>

        );
    }
}

CheckGoodsPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckGoodsPage);
