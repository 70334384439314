import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Card, CardContent, Typography, Snackbar, InputAdornment, Button } from '@material-ui/core';
import axios from 'axios';
import config from 'Config';
import moment from 'moment';

const CATEGORY_DRINK = 1;
const CATEGORY_GOODS = 2;
const CATEGORY_MEMBER = 3;
const CATEGORY_CHANGEMONEY = 4;

const styles = () => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    discountButton: {
        marginTop: '20px',
        marginLeft: '20px',
        fontSize: '10px',
        backgroundColor: 'yellow',
    },
    button: {
        marginTop: '40px',
        fontSize: '15px',
        backgroundColor: 'yellow',
        minWidth: '100%'
    }
});

class BarcodePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barcode: '',
            result: null,
            errorSnackBarOpen: false,
            total: 0.0,
            message: '',
            remark: '',
            change: 0,
            receive: 0,
            role: '',
            user: '',
            loggedIn: false,
            password: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.confirmHandler = this.confirmHandler.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSnackBarClose= this.handleSnackBarClose.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
        this.sevenOffClicked = this.sevenOffClicked.bind(this);
        this.sevenFiveOffClicked = this.sevenFiveOffClicked.bind(this);
        this.eightOffClicked = this.eightOffClicked.bind(this);
        this.eightFiveOffClicked = this.eightFiveOffClicked.bind(this);
        this.nineOffClicked = this.nineOffClicked.bind(this);
        this.getTotal = this.getTotal.bind(this);
        this.resetClicked = this.resetClicked.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.confirmClicked = this.confirmClicked.bind(this);
        this.handleCardChange = this.handleCardChange.bind(this);
        this.changeAllDrinkPrice = this.changeAllDrinkPrice.bind(this);
        this.changeMoneyClicked = this.changeMoneyClicked.bind(this);
        this.originalPressClicked = this.originalPressClicked.bind(this);
        this.calculateChange = this.calculateChange.bind(this);
        this.handleMoneyChange = this.handleMoneyChange.bind(this);
        this.focusBarcodeTextField = this.focusBarcodeTextField.bind(this);
        this.loginButtonClicked = this.loginButtonClicked.bind(this);
        this.logoutButtonClicked = this.logoutButtonClicked.bind(this);
        this.loginPressed = this.loginPressed.bind(this);
        this.barcodeInput = React.createRef();
    }

    handleSnackBarClose() {
        this.setState({errorSnackBarOpen: false});
    }

    componentDidMount(){
        document.addEventListener('keydown', this.focusBarcodeTextField, false);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.focusBarcodeTextField, false);
    }

    focusBarcodeTextField() {
        if(event.keyCode === 27) {
            this.barcodeInput.current.focus();
        }
    }

    loginButtonClicked() {
        axios.get(config.api + '/password?password=' + this.state.password).then((response) => {
            if (response.data !== undefined && response.data !== null) {
                this.setState({
                    loggedIn: true,
                    user: response.data.user
                });
            }
        });
    }

    logoutButtonClicked() {
        this.setState({
            loggedIn: false,
            user: ''
        });
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({user: newProps.user, loggedIn: newProps.loggedIn});
    }

    confirmHandler() {
        axios.get(config.api + '/barcode?barcode=' + this.state.barcode)
            .then((response) => {
                if (response.data.success == 0) {
                    this.setState({errorSnackBarOpen: true, barcode: '', message: '找不到結果。'});
                } else {
                    if (this.state.result != null) {
                        if (response.data.category == CATEGORY_MEMBER) {
                            const existingMember = this.state.result.filter((item) => { return item.category == CATEGORY_MEMBER; });
                            var result;
                            if (existingMember.length > 0 && response.data.category == CATEGORY_MEMBER) {
                                result = [response.data];
                            } else {
                                result = [response.data, ...this.state.result];
                            }
                            const memberCard = result.filter((item) => { return item.category == CATEGORY_MEMBER; });
                            if (memberCard != undefined) {
                                this.setState({role: memberCard[0].data.role});
                                if (memberCard[0].data.role == 'boss') {
                                    result.forEach((item) => {
                                        if (item.category == CATEGORY_DRINK) {
                                            item.data.price = 6;
                                            if (item.data.name == '統一奶茶(19)') {
                                                item.data.price = 7;
                                            }
                                        }
                                    });
                                } else if (memberCard[0].data.role == 'VIP') {
                                    result.forEach((item) => {
                                        if (item.category == CATEGORY_DRINK) {
                                            item.data.price = 10;
                                        }
                                    });
                                }
                            }
                            this.setState({ result, barcode: '', remark: response.data.data.name}, () => {
                                this.calculateTotal();
                            });
                        } else {
                            const memberCard = this.state.result.filter((item) => { return item.category == CATEGORY_MEMBER; });

                            var currentResult;
                            if (memberCard.length > 0 && response.data.category == CATEGORY_MEMBER) {
                                currentResult = [...this.state.result];
                            } else {
                                currentResult = [...this.state.result, response.data];
                            }
                            if (memberCard.length > 0) {
                                this.setState({role: memberCard[0].data.role});
                                if (memberCard[0].data.role == 'boss') {
                                    currentResult.forEach((item) => {
                                        if (item.category == CATEGORY_DRINK) {
                                            item.data.price = 6;
                                            if (item.data.name == '統一奶茶(19)') {
                                                item.data.price = 7;
                                            }
                                        }
                                    });
                                } else if (memberCard[0].data.role == 'VIP') {
                                    result.forEach((item) => {
                                        if (item.category == CATEGORY_DRINK) {
                                            item.data.price = 10;
                                        }
                                    });
                                }
                            }
                            this.setState({ result: currentResult, barcode: ''}, () => {
                                this.calculateTotal();
                            });
                        }
                    } else {
                        if (response.data.category == CATEGORY_MEMBER) {
                            this.setState({role: response.data.data.role});
                            this.setState({ result: [response.data], barcode: '', remark: response.data.data.name}, () => {
                                this.calculateTotal();
                            });
                        } else {
                            this.setState({ result: [response.data], barcode: '' }, () => {
                                this.calculateTotal();
                            });
                        }
                        
                    }
                }
            });
    }

    changeMoneyClicked(options) {
        const existingChangeMoney = this.state.result.filter((item) => { return item.category == CATEGORY_CHANGEMONEY; });
        if (existingChangeMoney.length > 0) {
            return;
        }
        var price;
        var name;
        switch (options) {
        case 0:
            name = '$50 唱 12 Credits';
            price = -10;
            break;
        case 1:
            name = '$50 唱 15 Credits';
            price = -25;
            break;
        case 2:
            name = '$100 唱 26 Credits';
            price = -30;
            break;
        case 3:
            name = '$100 唱 30 Credits';
            price = -50;
            break;
        case 4:
            name = '$100 唱 40 Credits';
            price = -100;
            break;
        }
        var changeMoney;
        changeMoney = {
            category: CATEGORY_CHANGEMONEY,
            data: {
                name,
                price
            }
        };
        this.setState({ 
            result: [...this.state.result, changeMoney]
        }, () => {
            this.calculateTotal();
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleMoneyChange(event) {
        this.setState({receive: event.target.value}, () => {
            this.calculateChange();
        });
    }

    handleCardChange(event) {
        var currentResult = this.state.result;
        var currentData = this.state.result[parseInt(event.target.name)];
        currentData.data.price = event.target.value;
        currentResult[parseInt(event.target.name)] = currentData;
        this.calculateTotal();
    }

    changeAllDrinkPrice(price) {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_DRINK) {
                r.data.price = price;
            }
        }
        this.calculateTotal();
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.confirmHandler();
        }
    }

    calculateTotal() {
        this.setState({total: this.getTotal()}, () => {
            if (this.getTotal() > 0) {
                this.calculateChange();
            }
        });
    }

    getTotal() {
        var total = 0;
        if (this.state.result != null) {
            total = this.state.result.reduce((accum, current) => {
                if (current.data.price != undefined && current.data.price != '') {
                    return accum += parseFloat(current.data.price);
                } else {
                    return 0.0;
                }
            }, 0.0);
        }
        return total;
    }

    sevenOffClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = (r.data.originalPrice * 0.7).toFixed(1);
            }
        }
        this.calculateTotal();
    }

    sevenFiveOffClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = (r.data.originalPrice * 0.75).toFixed(1);
            }
        }
        this.calculateTotal();
    }

    eightOffClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = (r.data.originalPrice * 0.8).toFixed(1);
            }
        }
        this.calculateTotal();
    }

    eightFiveOffClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = (r.data.originalPrice * 0.85).toFixed(1);
            }
        }
        this.calculateTotal();
    }

    nineOffClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = (r.data.originalPrice * 0.9).toFixed(1);
            }
        }
        this.calculateTotal();
    }

    originalPressClicked() {
        var currentResult = this.state.result;
        for (var i = 0; i < currentResult.length; i++) {
            var r = currentResult[i];
            if (r.category == CATEGORY_GOODS) {
                r.data.price = r.data.originalPrice;
            }
        }
        this.calculateTotal();
    }

    resetClicked() {
        this.setState({result: null, total: 0, remark: '', change: 0, receive: 0});
    }
    
    removeItem(i) {
        var originalResult = this.state.result;
        if (originalResult[i].category == CATEGORY_MEMBER) {
            this.setState({ remark: '', result: null, total: 0, receive: 0, change: 0, role: ''});
        } else {
            originalResult.splice(i, 1);
            this.setState({receive: 0, total: 0, change: 0});
            this.calculateTotal();
        }

    }

    confirmClicked() {     
        var filteredResult = this.state.result.filter((item) => { return item.category != CATEGORY_MEMBER;});
        var memberResult = this.state.result.filter((item) => { return item.category == CATEGORY_MEMBER;});

        filteredResult.map((item) => {
            var categoryId = item.category;
            var category = '飲品';
            var itemId;
            if (categoryId == 2) {
                category = '貨品';
                categoryId = 6;
            }
            if (categoryId == 1) {
                itemId = item.data.id;
            }
            if (categoryId == 4) {
                categoryId = 2;
                switch (item.data.name) {
                case '$50 唱 12 Credits':
                    itemId = 0;
                    break;
                case '$50 唱 15 Credits':
                    itemId = 1;
                    break;
                case '$100 唱 26 Credits':
                    itemId = 2;
                    break;
                case '$100 唱 30 Credits':
                    itemId = 3;
                    break;
                case '$100 唱 40 Credits':
                    itemId = 4;
                    break;
                }
                if (memberResult.length > 0 && itemId == 4) {
                    axios.post(config.api+'/member/use-coupon', {
                        id: memberResult[0].data.id,
                        couponId: 1
                    }).then(() => {
                        console.log('done');
                    });
                }
            }
            axios.post(config.api+'/log', {
                user: this.state.user,
                item: item.data.name,
                category,
                categoryId,
                itemId,
                amount: item.data.price,
                remark: this.state.remark,
                isDeleted: 0,
                qty: 1
            })
                .then(() => {
                    this.setState({
                        errorSnackBarOpen: true, 
                        message: moment().format('DD/MM/YYYY h:mm a') + ' 已完成',
                        result: null,
                        remark: '',
                        receive: 0,
                        total: 0,
                        change: 0
                    }, () => {
                        this.calculateTotal();
                    });
                });
        });

    }

    calculateChange() {
        this.setState({change: this.state.receive - this.state.total});
    }

    loginPressed(e) {
        if (e.key === 'Enter') {
            this.loginButtonClicked();
        }
    }

    render() {
        const { classes } = this.props;
        var resultCard;
        var receiveTextField;
        if (this.state.result != null) {
            resultCard = (
                this.state.result.map((item, index) => { 
                    if (item.category == CATEGORY_DRINK|| item.category == CATEGORY_GOODS) {
                        return(
                            <Card key={index} style={{ minWidth: '100%', margin: 8, marginTop: '20px' }}>
                                <CardContent style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography variant="h6"> {item.data.name} </Typography>
                                    <TextField
                                        id="price"
                                        label="價錢"
                                        style={{marginTop: '20px'}}
                                        InputProps={{
                                            name: String(index),
                                            startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                        }}
                                        value={item.data.price}
                                        // defaultValue={item.data.price}
                                        onChange={this.handleCardChange}
                                    />
                                </CardContent>
                                <Button onClick={() => {this.removeItem(index);}}>移除</Button>
                            </Card>
                        );
                    } else if (item.category == CATEGORY_MEMBER) {
                        var memberRank;
                        switch(item.data.role) {
                        case 'boss':
                            memberRank = 'D8';
                            break;
                        case 'staff':
                            memberRank = 'D8 Staff';
                            break;
                        case 'VIP': 
                            memberRank = '高級會員';
                            break;
                        case 'normal': 
                            memberRank = '會員';
                            break;
                        case 'point_card': 
                            memberRank = '儲分卡';
                            break;
                        }
                        var has40CreditsCoupon = false;
                        var coupon40Credits = item.data.coupons.filter((value) => {
                            return value.couponId == 1;
                        });
                        console.log(item.data);
                        if (coupon40Credits.length > 0) {
                            has40CreditsCoupon = true;
                        }
                        return(
                            <Card key={index} style={{ minWidth: '100%', margin: 8, marginTop: '20px' }}>
                                <CardContent style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography variant="h6"> 會員: {item.data.name} </Typography>
                                    <Typography variant="h6"> 會員編號: {item.data.id} </Typography>
                                    <Typography variant="h6"> 會員等級: {memberRank} </Typography>
                                    {(item.data.role == 'boss' || item.data.role == 'staff') && item.data.coupons.length > 0 &&
                                    <div>
                                        <Typography variant="h6"> 優惠券:</Typography>
                                        <Typography variant="h6"> $100 唱 40 Credits: {coupon40Credits.length} 張</Typography>
                                    </div>
                                    }
                                    {item.data.role == 'normal' &&
                                    <Button style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(0);}}>
                                        $ 50 唱 12 Credits
                                    </Button>}
                                    {(item.data.role == 'VIP' || item.data.role == 'boss' || item.data.role == 'staff') &&
                                    <Button style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(1);}}>
                                        $ 50 唱 15 Credits
                                    </Button>}
                                    {item.data.role == 'normal' &&
                                    <Button style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(2);}}>
                                        $ 100 唱 26 Credits
                                    </Button>}
                                    {(item.data.role == 'VIP' || item.data.role == 'boss' || item.data.role == 'staff') &&
                                    <Button style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(3);}}>
                                        $ 100 唱 30 Credits
                                    </Button>}
                                    {(item.data.role == 'boss' || item.data.role == 'staff') && has40CreditsCoupon == true &&
                                    <Button style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(4);}}>
                                        $ 100 唱 40 Credits
                                    </Button>}
                                    {(item.data.role == 'boss' || item.data.role == 'staff') && has40CreditsCoupon == false &&
                                    <Button disabled style={{marginTop: '10px', fontSize: '13px'}} className={classes.discountButton} variant="contained" onClick={() => { this.changeMoneyClicked(4);}}>
                                        $ 100 唱 40 Credits
                                    </Button>}
                                </CardContent>
                                <Button onClick={() => {this.removeItem(index);}}>移除</Button>
                            </Card>
                        );
                    } else if (item.category == CATEGORY_CHANGEMONEY) {
                        return(
                            <Card key={index} style={{ minWidth: '100%', margin: 8, marginTop: '20px' }}>
                                <CardContent style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography variant="h6"> 唱錢: {item.data.name} </Typography>
                                </CardContent>
                                <Button onClick={() => {this.removeItem(index);}}>移除</Button>
                            </Card>
                        );
                    }
                })
            );
        }
        if (this.state.total > 0) {
            receiveTextField = (<TextField
                id="receive"
                label="收取"
                value={this.state.receive}
                onChange={this.handleMoneyChange}
                className={classes.textField}
                InputProps={{
                    name: 'receive',
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                }}
                margin="normal"
            />);
        } else {
            receiveTextField = (<TextField
                id="receive"
                label="收取"
                disabled
                value={this.state.receive}
                onChange={this.handleMoneyChange}
                className={classes.textField}
                InputProps={{
                    name: 'receive',
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                }}
                margin="normal"
            />);
        }
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.errorSnackBarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />
                <TextField
                    id="outlined-full-width"
                    label="Barcode"
                    style={{ margin: 8 }}
                    fullWidth
                    autoFocus
                    margin="normal"
                    variant="outlined"
                    value={this.state.barcode}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    inputRef={this.barcodeInput}
                    inputProps={{
                        name: 'barcode',
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {resultCard}
                <hr />
                <TextField
                    id="remark"
                    label="備註"
                    InputProps={{
                        name: 'remark',
                    }}
                    style={{minWidth: '100%', marginTop:'1px'}}
                    value={this.state.remark}
                    onChange={this.handleChange}
                />
                <div className={classes.row}>
                    <TextField
                        id="standard-name"
                        label="總計"
                        disabled
                        value={this.state.total}
                        className={classes.textField}
                        InputProps={{
                            name: 'total',
                            startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                        }}
                        margin="normal"
                    />
                    <Button variant="contained" className={classes.discountButton} onClick={this.sevenOffClicked}>
                        7 折
                    </Button>
                    <Button variant="contained" className={classes.discountButton} onClick={this.sevenFiveOffClicked}>
                        75 折
                    </Button>
                    <Button variant="contained" className={classes.discountButton} onClick={this.eightFiveOffClicked}>
                        85 折
                    </Button>
                </div>
                <div className={classes.row}>
                    {receiveTextField}  
                    <Button variant="contained" className={classes.discountButton} onClick={this.eightOffClicked}>
                        8 折 (學生價)
                    </Button>
                    <Button variant="contained" className={classes.discountButton} onClick={this.nineOffClicked}>
                        9 折
                    </Button>
                    <Button variant="contained" className={classes.discountButton} onClick={this.originalPressClicked}>
                        原價
                    </Button>
                </div>
                <div className={classes.row}>
                    <TextField
                        id="change"
                        label="找續"
                        disabled
                        value={this.state.change}
                        className={classes.textField}
                        InputProps={{
                            name: 'change',
                            startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                        }}
                        margin="normal"
                    />
                </div>
                {this.state.loggedIn == true &&
                    <Button variant="contained" className={classes.button} onClick={this.confirmClicked}>
                        確定
                    </Button>
                }
                {this.state.loggedIn == false &&
                    <Button disabled variant="contained" className={classes.button} onClick={this.confirmClicked}>
                        確定
                    </Button>
                }

                <Button style={{marginTop: '20px'}} variant="contained" className={classes.button} onClick={this.resetClicked}>
                    重設
                </Button>
            </div>
        );
    }
}


BarcodePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BarcodePage);